import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@campaignhub/suit-theme'

import GeneralDetails from './components/GeneralDetails'
import LinkingDetails from './components/LinkingDetails'
import DuskTimeDetails from './components/DuskTimeDetails'

const ZoneDetails = (props) => {
  const { zoneForm, customErrors, callbacks, linkedClients, linkedProviders } = props
  const { setCustomErrors, toggleUpdateLinkedClients, toggleUpdateLinkedProviders } = callbacks

  return (
    <Box flexDirection="column">
      <GeneralDetails
        zoneForm={zoneForm}
        customErrors={customErrors}
        callbacks={{ setCustomErrors }}
      />
      <LinkingDetails
        zoneForm={zoneForm}
        callbacks={{ toggleUpdateLinkedClients, toggleUpdateLinkedProviders }}
        linkedClients={linkedClients}
        linkedProviders={linkedProviders}
      />
      <DuskTimeDetails
        zoneForm={zoneForm}
      />
    </Box>
  )
}

ZoneDetails.propTypes = {
  zoneForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
  linkedClients: PropTypes.array,
  linkedProviders: PropTypes.array,
}

export default ZoneDetails
