import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, SidebarModal, FileUploadZone, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { getUppyOptions, getComponentCallbacks } from '@functions/uploadModal'

import useUppyUploadVersion from '@hooks/useUppyUploadVersion'
import VersionList from './components/VersionList'

const defaultState = {
  allowedFileTypes: ['image/jpeg', 'image/jpg'],
  selectedFileType: 'image',
}

const fileTypes = (fileType) => {
  const availableFileTypes = {
    image: ['image/jpeg', 'image/jpg'],
    pdf: ['application/pdf'],
  }

  return availableFileTypes[fileType] || {}
}

const UploadVersionModal = (props) => {
  const {
    callbacks, modalKey, showModal, title, titleSecondLine, serviceJobOptions,
    type, displayAllowedFileTypes, selectedAsset, assetScreenSetState,
  } = props

  const { loadFn } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { allowedFileTypes, selectedFileType } = state

  const uppyCallbacks = { ...getComponentCallbacks('uppy', setState), loadFn }
  const uppyOptions = getUppyOptions(type, allowedFileTypes, serviceJobOptions, setState, uppyCallbacks, 0)
  const uppy = useUppyUploadVersion(uppyOptions)

  const changeFileType = (event) => {
    setState({ allowedFileTypes: fileTypes(event.target.value) })
    setState({ selectedFileType: event.target.value })
  }

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title={title} titleSecondLine={titleSecondLine} />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column" marginBottom="large">
          {displayAllowedFileTypes && (
            <FormField direction="column" label="Choose Type" marginTop="large" value={selectedFileType}>
              <select onChange={changeFileType}>
                <option value="image">Images</option>
                <option value="pdf">PDF</option>
              </select>
            </FormField>
          )}
          {uppy
            && (
            <FormField direction="column" marginTop="large">
              <FileUploadZone
                uppy={uppy}
                subTitle={`Max Size is ${uppyOptions.maxFileSize / 100000} mb`}
              />
            </FormField>
          )}
          {!uppy && (
            <FormField direction="column" marginTop="large">
              <Text marginBottom="small" fontSize="xsmall">
                Could not load uppy service
              </Text>
            </FormField>
          )}
        </Box>
        <Text marginBottom="large" fontSize="small" fontWeight="500">
          Versions
        </Text>
        <VersionList assetSetState={assetScreenSetState} contents={selectedAsset} callbacks={callbacks} />
      </SidebarModal.Content>
    </SidebarModal>
  )
}

UploadVersionModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  serviceJobOptions: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  displayAllowedFileTypes: PropTypes.bool.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  titleSecondLine: PropTypes.string,
  selectedAsset: PropTypes.object,
  assetScreenSetState: PropTypes.func,
}

UploadVersionModal.defaultProps = {
  modalKey: 'UploadVersionModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <UploadVersionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
