import {
  faList, faTachometer,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = ({ toggleNavigate }) => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          icon: faTachometer,
          key: 'dashboard',
          onClick: () => toggleNavigate('/'),
          title: 'Dashboard',
          visible: false,
        },
      ],
    },
    {
      key: 'overviews',
      title: 'Overviews',
      visible: true,
      items: [
        {
          icon: faList,
          key: 'jobs',
          onClick: () => toggleNavigate('/service-jobs'),
          title: 'Jobs',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
