import { useContext } from 'react'
import { useSelector } from 'react-redux'
import PageContext from '@contexts/pageContext'

const editUserFlags = (userFlags, providerFlags, showEditFlagsModal, type) => new Promise((resolve, reject) => {
  if (showEditFlagsModal){
    const payload = {
      flags: type === 'provider' ? providerFlags : userFlags,
      type,
    }

    showEditFlagsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditFlagsModal not defined in PageContext callbacks'))
})

const useFlags = () => {
  const { callbacks } = useContext(PageContext)
  const { showEditFlagsModal } = callbacks || {}

  const { result: userFlagsV3 } = useSelector(reduxState => reduxState.userFlagsV3)
  const { result: providerFlagsV3 } = useSelector(reduxState => reduxState.providerFlagsV3)

  return {
    callbacks: {
      editUserFlags: type => editUserFlags(userFlagsV3, providerFlagsV3, showEditFlagsModal, type),
    },
  }
}

export default useFlags
