import React from 'react'
import PropTypes from 'prop-types'

import { Box, Line, SidebarModal } from '@campaignhub/suit-theme'

import ActionButtons from './components/ActionButtons'
import AssetDetails from './components/AssetDetails'
import ProofingNotes from './components/ProofingNotes'
import SidebarList from './components/SidebarList'

const AssetSettingsModal = (props) => {
  const { callbacks, disableAnimation, disableOverlay, modalKey, showModal } = props

  return (
    <SidebarModal
      callbacks={callbacks}
      disableAnimation={disableAnimation}
      disableOverlay={disableOverlay}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="Settings" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column">
          <AssetDetails />
          <Line margin={0} />

          <ActionButtons boxProps={{ padding: 'large' }} />
          <Line margin={0} />

          <Box flexDirection="column" padding="large">
            <ProofingNotes />
          </Box>
          <Line margin={0} />

          <SidebarList />
          <Line margin={0} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetSettingsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  disableAnimation: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetSettingsModal.defaultProps = {
  modalKey: 'AssetSettingsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetSettingsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
