import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PageContext from '@contexts/pageContext'
import { Box, Button, Grid, LoadingModule, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

import custom from '@styles/custom.module.scss'
import Header from '../Header'
import ZoneCapacity from '../ZoneCapacity'
import ZoneList from '../ZoneList'

const MainPanel = () => {
  const { callbacks, categories, selectedWeek, zones } = useContext(PageContext)
  const { toggleUpdateSelectedWeek } = callbacks

  const { loading } = useSelector(reduxState => reduxState.capacities)

  return (
    <Box className={custom.root} flexDirection="column">
      <Box
        backgroundColor="white"
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        flexDirection="column"
        marginBottom="large"
        width="100%"
      >
        <Box borderBottom="1px solid" borderColor="lineColor" padding="large" width="auto" alignItems="center">
          <Box alignItems="center" justifyContent="flex-start" marginRight="auto" width="auto">
            {selectedWeek.id > -1 ? (
              <Button
                border={0}
                buttonStyle="secondary"
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={() => toggleUpdateSelectedWeek(selectedWeek.id - 1)}
                padding="0!important"
                size="small"
              />
            ) : null}
          </Box>
          <Text letterSpacing="0.5px" fontSize="small" width="auto">
            {selectedWeek.name.toUpperCase()}
          </Text>
          <Box alignItems="center" justifyContent="flex-end" marginLeft="auto" width="auto">
            {selectedWeek.id < 8 ? (
              <Button
                border={0}
                buttonStyle="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={() => toggleUpdateSelectedWeek(selectedWeek.id + 1)}
                padding="0!important"
                size="small"
              />
            ) : null}
          </Box>
        </Box>
        {categories.length > 0 && zones.length > 0 && !loading ? (
          <Grid
            gridTemplateColumns="180px auto"
            gridTemplateRows="auto"
            gridTemplateAreas="
              'header-space header'
              'zones-container zones-capacity'
            "
            maxHeight="60vh"
            overflow="auto"
          >
            <Grid
              backgroundColor="white"
              gridArea="header-space"
              left="0"
              position="sticky"
              style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
              top="0"
              zIndex="7"
            />
            <Header />
            <ZoneList />
            <ZoneCapacity />
          </Grid>
        ) : <LoadingModule boxProps={{ border: 0 }} />}
      </Box>
    </Box>
  )
}

export default MainPanel
