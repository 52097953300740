/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, Columns, LoadingModule, ModalContext, TabBar, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJobUser from '@hooks/useServiceJobUser'

import ToolsFilter from '@components/ToolsFilter'
import ServiceJobFilter from '@components/ServiceJobFilter'
import ServiceJobList from '@components/ServiceJobList'
import Statistics from '@components/Statistics'

import PageContext from '@contexts/pageContext'
import CreateServiceJobViewModal from '@modals/CreateServiceJobViewModal'
import EditServiceJobViewModal from '@modals/EditServiceJobViewModal'

import customFilters from '@functions/customFilters'
import { getEntityByName } from '@functions/getEntityByName'

import custom from '@styles/custom.module.scss'

const createFilter = (filterParams, createFn, setState) => {
  createFn(filterParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateServiceJobViewModal: false })
  })
}

const updateFilter = (filter, updateFn, setState) => {
  updateFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
  })
}

const deleteFilter = (filter, deleteFn, setState) => {
  deleteFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
    toast.success('View Deleted')
  })
}

const assignServiceJobUser = (serviceJobUserParams, jobAssigned, createFn, navigate) => {
  if (jobAssigned){
    toast.success('Assigned Job')
    navigate(`/service-jobs/${jobAssigned}`)
    return
  }

  if (serviceJobUserParams){
    createFn(serviceJobUserParams).then(({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warning(errors[0])
        return
      }

      if (success){
        toast.success('Job Taken')
        window.location.href = redirectUrl
      }
    })
  }
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CreateServiceJobViewModal: {
      closeModal: () => setState({ showCreateServiceJobViewModal: false }),
      createFilter: (filterParams, createFn) => createFilter(filterParams, createFn, setState),
    },
    EditServiceJobViewModal: {
      closeModal: () => setState({ showEditServiceJobViewModal: false }),
      deleteFilter: (filter, deleteFn) => deleteFilter(filter, deleteFn, setState),
      updateFilter: (filter, updateFn) => updateFilter(filter, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  activeTabBarItemKey: {
    key: 'jobs',
    filterStatuses: [],
    takeStatuses: [],
    takeRole: [],
  },
  clientFilter: [],
  customFilter: [
    customFilters.studioNotDone.id,
    customFilters.notArchived.id,
  ],
  dateFilter: {
    filterEnd: '',
    filterStart: '',
  },
  expandedFilter: false,
  expandedTools: true,
  filterLimit: 50,
  hideArchivedJobs: true,
  hideDoneJobs: true,
  serviceFilter: [],
  jobAssigned: null,
  setTotal: 0,
  showCreateServiceJobViewModal: false,
  showEditServiceJobViewModal: false,
  statusFilter: [],
  userFilter: [],
  userToAssign: null,
  assigned: false,
}

const ServiceJobs = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    activeTabBarItemKey, clientFilter, customFilter, dateFilter, expandedFilter, expandedTools, filterLimit, hideArchivedJobs, hideDoneJobs, jobAssigned, serviceFilter, showCreateServiceJobViewModal, showEditServiceJobViewModal, statusFilter, userFilter, userToAssign,
  } = state

  const navigate = useNavigate()

  const { currentUser } = useCurrentUser()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = {
    callbacks: {
      showCreateServiceJobViewModal: (payload) => {
        setModalData('CreateServiceJobViewModal', payload)
        setState({ showCreateServiceJobViewModal: true })
      },
      showEditServiceJobViewModal: (payload) => {
        setModalData('EditServiceJobViewModal', payload)
        setState({ showEditServiceJobViewModal: true })
      },
    },
  }

  useEffect(() => {
    const filters = []
    if (hideDoneJobs) filters.push(customFilters.studioNotDone.id)
    if (hideArchivedJobs) filters.push(customFilters.notArchived.id)

    setState({ customFilter: filters })
  }, [hideDoneJobs, hideArchivedJobs])

  const includes = ['campaign', 'client', 'details', 'service', 'status', 'users', 'notes']
  const options = {
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...customFilter?.length && { customFilters: customFilter.join(',') },
    ...dateFilter?.filterStart && { filterStart: dateFilter.filterStart },
    ...dateFilter?.filterEnd && { filterEnd: dateFilter.filterEnd },
    includes: includes.join(','),
    ...serviceFilter?.length && { services: serviceFilter.join(',') },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
    pageSize: filterLimit,
    pageNumber: 1,
    orderBy: 'clientDeadline',
  }

  useReduxAction('serviceJobs', 'loadServiceJobs', options, [
    clientFilter, customFilter, dateFilter, serviceFilter, statusFilter, userFilter, filterLimit,
  ])

  const {
    loading, results: filteredServiceJobs,
  } = useSelector(reduxState => reduxState.serviceJobs)

  function setActiveTabBarItemKey(key, filterStatuses, takeStatuses, takeRole){
    setState({
      activeTabBarItemKey: {
        key,
        filterStatuses,
        takeStatuses,
        takeRole,
      },
      statusFilter: filterStatuses,
    })
  }

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const qcTakeStatuses = [
    getEntityByName(serviceJobStatuses, 'AwaitingQC')?.id,
  ]
  const qcFilterStatuses = [...qcTakeStatuses, getEntityByName(serviceJobStatuses, 'InQC')?.id]

  const rejectionTakeStatuses = [
    getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id,
  ]
  const rejectionFilterStatuses = [getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id]

  const serviceJobUserPayload = useServiceJobUser()
  const {
    callbacks: { assignServiceJobUser: createFn },
  } = serviceJobUserPayload

  const takeAServiceJob = (activeTab) => {
    setState({ jobAssigned: null })

    if (activeTab.key === 'jobs' || Object.values(filteredServiceJobs).length === 0) return

    const serviceJobUserRoleId = activeTab.key === 'qc' ? 2 : 3
    let takeJob = Object.values(filteredServiceJobs).find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id)
      && serviceJob.users.find(serviceJobUser => serviceJobUser.serviceJobUserRoleId === serviceJobUserRoleId && serviceJobUser.user.id === currentUser.id))

    if (!takeJob){
      takeJob = Object.values(filteredServiceJobs).find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id))

      if (takeJob){
        setState({
          userToAssign: {
            serviceJobId: takeJob.id,
            userId: currentUser.id,
            serviceJobUserRoleId,
          },
        })
      }
      return
    }

    if (takeJob) setState({ jobAssigned: takeJob.id })
  }

  useEffect(() => {
    takeAServiceJob(activeTabBarItemKey)
  }, [filteredServiceJobs])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        {expandedFilter && <ServiceJobFilter setJobState={setState} jobState={state} showCustomView />}
        <Box paddingX="large" paddingTop="large">
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column-reverse', 'column-reverse', 'row']}>
            <Columns.Main>
              <TabBar
                items={[
                  {
                    key: 'jobs',
                    title: 'Jobs',
                    onClick: () => setActiveTabBarItemKey('jobs', [], []),
                  },
                  {
                    key: 'qc',
                    title: 'QC',
                    onClick: () => setActiveTabBarItemKey('qc', qcFilterStatuses, qcTakeStatuses, 2),
                  },
                  {
                    key: 'rejection',
                    title: 'Rejection',
                    onClick: () => setActiveTabBarItemKey('rejection', rejectionFilterStatuses, rejectionTakeStatuses, 3),
                  },
                ]}
                selectedItemKey={activeTabBarItemKey.key}
                invertColors
              />
              {loading ? <LoadingModule loading={loading} /> : (
                <>
                  {Object.values(filteredServiceJobs).length > 0 && (
                    <Box
                      flexDirection="row"
                      paddingBottom="medium"
                      alignItems="center"
                      backgroundColor="white"
                      padding="large"
                      borderLeft="1px solid"
                      borderRight="1px solid"
                      borderColor="lineColor"
                    >
                      <Text fontSize="small">
                        {Object.values(filteredServiceJobs).length}{Object.values(filteredServiceJobs).length > 1 ? ' Jobs' : ' Job'}
                      </Text>
                      <Box alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
                        {activeTabBarItemKey.key !== 'jobs' && (jobAssigned || userToAssign !== null)
                          && (
                            <Button
                              buttonStyle="ghostCreate"
                              size="medium"
                              onClick={() => assignServiceJobUser(userToAssign, jobAssigned, createFn, navigate)}
                              width="small"
                            >
                              Take a {activeTabBarItemKey.key === 'qc' ? 'QC' : 'Rejection'} Job
                            </Button>
                          )}
                      </Box>
                    </Box>
                  )}
                  <Box flexDirection="column" variant="white" className={custom.scroll}>
                    <ServiceJobList
                      serviceJobs={Object.values(filteredServiceJobs)}
                      showUsers
                      showProvider={activeTabBarItemKey.key === 'jobs'}
                      showQC={activeTabBarItemKey.key !== 'rejection'}
                      showRejection={activeTabBarItemKey.key !== 'qc'}
                    />
                  </Box>
                </>
              )}

            </Columns.Main>
            {expandedTools
              && (
                <Columns.Sidebar>
                  <ToolsFilter
                    doneToggleText="Approved"
                    setJobState={setState}
                    jobState={state}
                    showStudioUserFilter
                    showServiceUserFilter
                    showFilterByLimit
                    showManageFilter
                  />
                  <Statistics hidden />
                </Columns.Sidebar>
              )}
          </Columns>
        </Box>
        <CreateServiceJobViewModal
          callbacks={callbacks('CreateServiceJobViewModal', props, setState)}
          showModal={showCreateServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />

        <EditServiceJobViewModal
          callbacks={callbacks('EditServiceJobViewModal', props, setState)}
          showModal={showEditServiceJobViewModal}
          clientFilter={clientFilter}
          dateFilter={dateFilter}
          serviceFilter={serviceFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ServiceJobs
