import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useCurrentUser = () => {
  const includes = ['userRole', 'userRoleModules', 'serviceJobStatuses', 'services', 'userIdentity']
  const options = { includes: includes.join(',') }
  useReduxAction(
    'currentUser',
    'loadCurrentUser',
    options,
    [],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return !loaded && !loading && !errors.length
      },
    },
  )

  const entityReducer = useSelector(reduxState => reduxState.currentUser)
  const {
    errors,
    loaded,
    loading,
    result: currentUser,
  } = entityReducer

  return {
    currentUser,
    errors,
    loaded,
    loading,
  }
}

export default useCurrentUser
