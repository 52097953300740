import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, ListItem, SidebarModal } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'
import serviceJobNoteTypes from '@functions/serviceJobNoteTypes'

import ServiceJobNote from '@components/ServiceJobNote'
import QCScore from '@components/QCScore'
import Checklist from './components/Checklist'

const AssetEditorModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { currentUser, serviceJob, users, assignedUserRole } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="Editor" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" paddingX="large">
          <ListItem
            boxProps={{
            borderBottom: '1px dashed',
            paddingY: 'large',
            paddingBottom: serviceJob.postProductionChecklistItems?.length > 0 ? 0 : 'large',
          }}
            flexDirection="column"
            disableHover
            style={{ cursor: 'pointer' }}
          >
            <Checklist postProductionChecklist={serviceJob.postProductionChecklistItems || []} />
          </ListItem>
          {(['ProductionManager'].includes(users[currentUser.id]?.userRole.name)
          || assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'QualityControl')) && (
            <ListItem
              boxProps={{
                borderBottom: '1px dashed',
                paddingY: 'large',
              }}
              flexDirection="column"
              disableHover
              style={{ cursor: 'pointer' }}
            >
              <QCScore serviceJob={serviceJob} />
            </ListItem>
          )}
          <ListItem
            boxProps={{
              borderBottom: '1px dashed',
              paddingY: 'large',
            }}
            flexDirection="column"
            disableHover
            style={{ cursor: 'pointer' }}
          >
            <ServiceJobNote
              editable
              title={serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting' ? "COPYWRITER'S NOTE" : "EDITOR'S NOTE"}
              serviceJobId={serviceJob.id}
              serviceJobNoteTypeId={serviceJobNoteTypes.studio.id}
            />
          </ListItem>
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetEditorModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetEditorModal.defaultProps = {
  modalKey: 'AssetEditorModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetEditorModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
