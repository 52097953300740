import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import {
  Box, Button, IconWithMenu, Link, Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons'
import useUploadAssetVersion from '@hooks/useUploadAssetVersion'

import Icons from '@components/Icons'
import calculateContentOffset from '@components/utils/calculateContentOffset'

const ToolBar = (props) => {
  const {
    callbacks: { toggleSaveCopy, toggleShowAllMarkers }, copyFieldDisabled,
    showAllMarkers, showControls, showSaveCopy, showSend, showMarkerToggle,
  } = props

  const contentOffset = calculateContentOffset(0, {})

  const { serviceJob } = useContext(PageContext)

  const useUploadAssetVersionPayload = useUploadAssetVersion()
  const {
    callbacks: {
      launchAssetUploadVersionModal,
    },
    creating: creatingAsset,
    updating: updatingAsset,
  } = useUploadAssetVersionPayload

  return (
    <Box
      bottom={0}
      display="flex"
      flexDirection="row"
      padding="medium"
      position="fixed"
      variant="white"
      zIndex="3"
      width={`calc(100% - ${contentOffset || 0}px)`}
    >
      {showControls && (
      <>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ZoomIn" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ZoomOut" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ViewFullScreen" />} />
        </Box>
        <Box alignItems="center" flexShrink="0" padding="small" width="auto">
          <Button buttonStyle="secondary" size="medium" icon={<Icons name="ExitFullScreen" />} />
        </Box>
      </>
      )}
      {showSaveCopy && (
        <>
          <Box alignItems="center" flexShrink="0" padding="small" width="auto">
            <Button
              buttonStyle="primaryCreate"
              disabled={copyFieldDisabled && !updatingAsset && !creatingAsset}
              loading={creatingAsset || updatingAsset}
              onClick={() => toggleSaveCopy()}
              size="medium"
            >
              Save Copy
            </Button>
          </Box>
          <Box alignItems="center" flexShrink="0" padding="small" width="auto">
            <Button
              buttonStyle="primaryCreate"
              disabled={copyFieldDisabled && !updatingAsset && !creatingAsset}
              loading={creatingAsset || updatingAsset}
              onClick={() => toggleSaveCopy('scan')}
              size="medium"
            >
              Scan Copy and Save
            </Button>
          </Box>
        </>
      )}
      {showMarkerToggle && (
        <>
          <Box alignItems="center" flexShrink="0" padding="small" width="auto">
            <Toggle
              onClick={() => toggleShowAllMarkers()}
              toggleOffColor="bodyFontColor"
              toggleOffText="Show All Comments"
              toggleOn={showAllMarkers}
              toggleOnText="Show All Comments"
            />
          </Box>
          <Box flexShrink="0" marginLeft="auto" padding="small" width="auto">
            <Button
              size="medium"
              buttonStyle="secondaryUtility"
              disabled={serviceJob.serviceJobStatusId === 150}
              onClick={launchAssetUploadVersionModal}
            >
              Versions
            </Button>
          </Box>
        </>
      )}
      {showSend && (
        <Box flexShrink="0" alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto">
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="ghostEdit"
                icon={<FontAwesomeIcon icon={faAngleUp} />}
                iconPosition="right"
                loading={updatingAsset}
                size="medium"
              >
                Send
              </Button>
              )}
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <IconWithMenu.Menu
              closeOnClick
              listStyle={{ right: 0, left: '-55px', top: '-170px' }}
              nibStyle={{ top: '148px', transform: 'rotate(225deg)' }}
            >
              <Link>Send to QC</Link>
              <Link>Send to Editor</Link>
              <Link>Send to Client</Link>
              <Link>Send to Verification</Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        </Box>
      )}
    </Box>
  )
}

ToolBar.propTypes = {
  callbacks: PropTypes.object,
  copyFieldDisabled: PropTypes.bool,
  showAllMarkers: PropTypes.bool,
  showControls: PropTypes.bool,
  showMarkerToggle: PropTypes.bool,
  showSaveCopy: PropTypes.bool,
  showSend: PropTypes.bool,
}

export default ToolBar
