/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Image, Text, ListItemWithImage, StatusBadge,
} from '@campaignhub/suit-theme'

import Icons from '@components/Icons'

import { faSlidersH } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import custom from '@styles/custom.module.scss'

const defaultState = {
  resourceTypeFilter: [],
  selectedResourceTypes: [],
  showFilter: false,
}

const ClientResources = (props) => {
  const { resources, serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const { resourceTypeFilter, selectedResourceTypes, showFilter } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    const resourceTypes = Object.keys(resources).filter((x) => {
      if (resources[x].files.length > 0) return x
    })
    setState({ resourceTypeFilter: resourceTypes })
  }, [])

  const updateFilterState = (checked, type) => {
    let selected = selectedResourceTypes
    if (checked){
      selected = type === 'All' ? resourceTypeFilter : [...selectedResourceTypes, type]
    } else {
      selected = type === 'All' ? [] : selected.filter(i => i !== type)
    }

    setState({ selectedResourceTypes: selected })
  }

  return (
    <>
      {resourceTypeFilter.length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="lineColor"
            flexDirection="row"
            padding="large"
            className={custom.root}
            ref={contentEl}
          >
            <Box
              color="blue"
              flexDirection="row"
              onClick={() => setState({ showFilter: !showFilter })}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={faSlidersH} />
              <Text paddingX="medium" color="blue">Filter</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
            {showFilter && (
              <Box className={custom.popup} width="300px">
                <Box flexDirection="column" maxHeight="280px" overflowY="auto">
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <input
                      type="checkbox"
                      className={custom.checkbox}
                      onChange={e => updateFilterState(e.target.checked, 'All')}
                    />
                    <Text fontSize="small" marginLeft="medium">Select All</Text>
                  </Box>
                  {resourceTypeFilter.map(type => (
                    <Box flexDirection="row" key={type} paddingBottom="medium" alignItems="center">
                      <input
                        type="checkbox"
                        className={custom.checkbox}
                        onChange={e => updateFilterState(e.target.checked, type)}
                        checked={selectedResourceTypes.includes(type)}
                      />
                      <Text fontSize="small" marginLeft="medium">{resources[type].description}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            {(selectedResourceTypes.length > 0 ? selectedResourceTypes : resourceTypeFilter).map(resouceType => (
              <Box flexDirection="column" key={resources[resouceType].description} paddingBottom="large">
                <Text fontSize="small" fontWeight={600} marginBottom="large">
                  {resources[resouceType].description}
                </Text>
                {resources[resouceType].files.map(content => (
                  <ListItemWithImage imageComponent={(
                    <Box
                      alignItems="center"
                      as="a"
                      borderRight={['none', '1px solid']}
                      borderBottom={['1px solid', 'none']}
                      borderColor={['lineColor', 'lineColor']}
                      href={content.fileOrig}
                      justifyContent="center"
                      padding="medium"
                      style={{ textDecoration: 'none' }}
                      width={['100%', 'fit-content']}
                      target="_blank"
                    >
                      <Image
                        boxProps={{ backgroundColor: 'hoverLightGrey' }}
                        borderRadius={5}
                        height={[180, '100%']}
                        url={['jpg'].includes(content.fileExt) ? content.fileLink : ''}
                        width={['100%', 200]}
                      >
                        {!['jpg'].includes(content.fileExt) && (
                          <Box
                            alignItems="center"
                            color="bodyFontColor"
                            display="grid"
                            justifyContent="center"
                            width="100%"
                          >
                            <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                          </Box>
                        )}
                      </Image>
                    </Box>
                    )}
                  >
                    <ListItemWithImage.Header>
                      <ListItemWithImage.HeaderLeft>
                        <ListItemWithImage.HeaderText>
                          {content.name}
                        </ListItemWithImage.HeaderText>
                        {content.galleryCode && (
                          <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small">
                            Uploaded By: {content.uploader}
                          </Text>
                        )}
                        {content.galleryCode && (
                        <Text color="bodyFontLightColor" fontSize="xsmall">
                          Gallery Code: {content.galleryCode}
                        </Text>
                        )}
                      </ListItemWithImage.HeaderLeft>
                    </ListItemWithImage.Header>

                    <ListItemWithImage.Footer>
                      <ListItemWithImage.FooterLeft boxProps={{ maxWidth: '75%!important' }}>
                        <Box fontSize="small" marginBottom="small">
                          <StatusBadge
                            color="mysteryGrey"
                            text={content.fileExt}
                            boxProps={{ marginRight: 'small' }}
                          />
                        </Box>
                        {content.description && (
                          <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="small">
                            {content.description}
                          </Text>
                        )}
                        {content.comment && (
                          <Text color="bodyFontLightColor" fontSize="xsmall">
                            {content.comment}
                          </Text>
                        )}
                      </ListItemWithImage.FooterLeft>
                    </ListItemWithImage.Footer>
                  </ListItemWithImage>
                  ))}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {!resourceTypeFilter.length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No client resources available.
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

ClientResources.propTypes = {
  resources: PropTypes.object,
  serviceJob: PropTypes.object,
}

export default ClientResources
