/* eslint-disable consistent-return */
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import useServiceJobUserRoles from '@hooks/useServiceJobUserRoles'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useUsers from '@hooks/useUsers'
import { useServiceJobUserForm } from '@hooks/useServiceJobUser'

const assignOrUpdateServiceJobUser = (entityState, props, createFn, updateFn) => {
  const { callbacks: { assignServiceJobUser, updateServiceJobUser } } = props

  if (entityState.id) return updateServiceJobUser(entityState, updateFn)

  assignServiceJobUser(entityState, createFn)
}

const defaultState = {
  filteredUsers: [],
}

const ChangeJobAssignmentModal = (props) => {
  const { callbacks, modalKey, showModal, serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const { filteredUsers } = state

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const serviceJobUserPayload = digObject(modalData, `${modalKey}`, {})
  const {
    serviceJobUser,
    callbacks: {
      assignServiceJobUser: createFn,
      updateServiceJobUser: updateFn,
    },
  } = serviceJobUserPayload

  const serviceJobUseFormPayload = useServiceJobUserForm(serviceJobUser)

  const { creating, updating } = useSelector(reduxState => reduxState.serviceJobUsers)

  const {
    entityState,
    entityState: {
      user,
      serviceJobUserRole,
      serviceJobUserRoleId,
      userId,
    },
    handlers,
    saveEnabled,
    errors,
    setEntityState,
  } = serviceJobUseFormPayload

  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJobUser.serviceJobId)
  const { serviceJobUserRoles } = useServiceJobUserRoles()
  const { users } = useUsers({ userTypes: [5] })

  useEffect(() => {
    setEntityState({
      serviceJobUserRoleId: serviceJobUserRole.id || serviceJobUserRoleId,
      userId: user.id || userId,
    })

    if (!user.id){
      setEntityState({ serviceJobUserRoleId: serviceJob?.serviceJobStatusId >= 100 ? 2 : 1 })
    }
  }, [serviceJob])

  useEffect(() => {
    const filtered = Object.values(users)?.filter(x => x.userRole?.userTypeId === 5)
      .filter(x => x.userServices.some(y => y.serviceId === serviceJob?.service?.id))

    setState({ filteredUsers: filtered })
  }, [users, serviceJob])

  useEffect(() => {
    const tempUser = filteredUsers.find(x => x.id === parseInt(userId, 10)
      && (parseInt(serviceJobUserRoleId, 10) === 1 ? [16, 17].includes(x.userRole.id) : true))

    setEntityState({ userId: tempUser ? tempUser.id : 0 })
  }, [serviceJobUserRoleId])

  const validateServiceJobUser = () => {
    const duplicate = filteredServiceJobUsers.find(x => x.serviceJobUserRole.id === parseInt(serviceJobUserRoleId, 10)
      && x.user.id === parseInt(userId, 10)
      && (x.id !== serviceJobUser.id && x.id > 0))

    if (duplicate){ return toast.warning('User has already been assigned to the role') }

    assignOrUpdateServiceJobUser(entityState, props, createFn, updateFn)
  }

  const isEditMode = !!serviceJobUser.id

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title={isEditMode ? 'Change Assignment' : 'Assign User'}
      />
      <SidebarModal.Content>
        <Box flexDirection="column">
          <FormField direction="column" label="Role" errorMessage={errors.serviceJobUserRoleId}>
            <select
              name="serviceJobUserRoleId"
              data-validate-field-on="change"
              value={serviceJobUserRoleId}
              {...handlers}
            >
              <option value={0} disabled>Please Select...</option>
              {Object.values(serviceJobUserRoles).filter(x => x.name !== 'RevisionAssignee').map(x => (
                <option key={x.id} value={x.id}>
                  {x?.description}
                </option>
              ))}
            </select>
          </FormField>
          <FormField direction="column" label="User" marginTop="large" errorMessage={errors.userId}>
            <select
              name="userId"
              data-validate-field-on="change"
              disabled={isEditMode}
              value={userId}
              {...handlers}
            >
              <option value={0} disabled>Please Select...</option>
              {filteredUsers.filter(x => (parseInt(serviceJobUserRoleId, 10) === 1 ? [16, 17].includes(x.userRole.id) : x)).map(x => (
                <option key={x.id} value={x.id}>
                  {x.firstName} {x.lastName}
                </option>
                ))}
            </select>
          </FormField>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={validateServiceJobUser}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ChangeJobAssignmentModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  serviceJob: PropTypes.object,
}

ChangeJobAssignmentModal.defaultProps = {
  modalKey: 'ChangeJobAssignmentModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ChangeJobAssignmentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
