import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, ListItem, Toggle,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/pro-light-svg-icons'

import DateFilter from './components/DateFilter'
import FilterByLimit from './components/FilterByLimit'
import ClientFilter from './components/ClientFilter'
import StudioUserFilter from './components/StudioUserFilter'
import ServiceUserFilter from './components/ServiceUserFilter'

const ToolsFilter = (props) => {
  const { doneToggleText, setJobState, showFilterByLimit, showManageFilter, showServiceUserFilter, showStudioUserFilter, jobState } = props
  const { expandedFilter, hideArchivedJobs, hideDoneJobs } = jobState

  const resetAllFilters = () => {
    setJobState({
      clientFilter: [],
      dateFilter: {
        filterEnd: '',
        filterStart: '',
      },
      filterLimit: 50,
      serviceFilter: [],
      statusFilter: [],
      userFilter: [],
    })
  }

  return (
    <DashboardModule boxProps={{ marginBottom: 'large' }} title="Tools">
      <Box flexDirection="column">
        {showManageFilter && (
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
            disableHover
          >
            <Button
              buttonStyle="secondary"
              size="medium"
              icon={<FontAwesomeIcon icon={faSlidersH} />}
              onClick={() => setJobState({ expandedFilter: !expandedFilter })}
            >
              Manage Filters
            </Button>
          </ListItem>
        )}
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <Box marginBottom="medium">
            <Toggle
              onClick={() => setJobState({ hideDoneJobs: !hideDoneJobs })}
              toggleOffColor="bodyFontColor"
              toggleOffText={`Show ${doneToggleText || 'Done'} Jobs`}
              toggleOn={!hideDoneJobs}
              toggleOnText={`Show ${doneToggleText || 'Done'} Jobs`}
            />
          </Box>
          <Box>
            <Toggle
              onClick={() => setJobState({ hideArchivedJobs: !hideArchivedJobs })}
              toggleOffColor="bodyFontColor"
              toggleOffText="Show Archived Jobs"
              toggleOn={!hideArchivedJobs}
              toggleOnText="Show Archived Jobs"
            />
          </Box>
        </ListItem>
        <ListItem
          boxProps={{
            borderBottom: '1px solid',
            padding: 'large',
          }}
          flexDirection="column"
          disableHover
        >
          <DateFilter setJobState={setJobState} jobState={jobState} />
          <ClientFilter setJobState={setJobState} jobState={jobState} />
          {showStudioUserFilter && <StudioUserFilter setJobState={setJobState} jobState={jobState} />}
          {showServiceUserFilter && <ServiceUserFilter setJobState={setJobState} jobState={jobState} />}
          {showFilterByLimit && <FilterByLimit setJobState={setJobState} jobState={jobState} />}
          <Button buttonStyle="secondary" size="medium" onClick={() => resetAllFilters()}>
            Reset All Filters
          </Button>
        </ListItem>
      </Box>
    </DashboardModule>
  )
}

ToolsFilter.propTypes = {
  doneToggleText: PropTypes.string,
  setJobState: PropTypes.func,
  showFilterByLimit: PropTypes.bool,
  showManageFilter: PropTypes.bool,
  showServiceUserFilter: PropTypes.bool,
  showStudioUserFilter: PropTypes.bool,
  jobState: PropTypes.object,
}

export default ToolsFilter
