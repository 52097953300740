import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, Image, Link, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import Icons from '@components/Icons'
import PageContext from '@contexts/pageContext'

import { useResourceForm } from '@hooks/useResource'

const updateResourceDetail = (entityState, props, updateFn) => {
  const { callbacks: { updateResource } } = props

  updateResource(entityState, updateFn)
}

const EditResourceDetailModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const resourcePayload = digObject(modalData, modalKey, {})

  const {
    resource,
    callbacks: { updateResource: updateFn },
  } = resourcePayload

  const resourceFormPayload = useResourceForm(resource)

  const { updating } = useSelector(reduxState => reduxState.resources)

  const {
    entityState,
    entityState: {
      description,
      displayName,
    },
    errors,
    handlers,
  } = resourceFormPayload

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title="Edit"
        titleSecondLine="Resource"
      />
      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="small" borderRadius={5}>
          <Link href={`${resource?.resourceUrl}`} target="_blank">
            <Image
              backgroundPosition="center top"
              borderRadius={5}
              boxProps={{ backgroundColor: 'hoverLightGrey' }}
              width="100%"
              height={230}
              url={resource?.file.previewGeneratedAt ? resource?.previewUrl : ''}
            >
              {!resource?.file.previewGeneratedAt && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                </Box>
              )}
            </Image>
          </Link>
        </Box>
        <Text color="bodyFontLightColor" fontSize="xsmall" textAlign="center">
          {resource?.file.originalName}
        </Text>
        <FormField direction="column" label="Display Name" marginTop="large" errorMessage={errors.displayName}>
          <input name="displayName" type="text" value={displayName} {...handlers} data-validate-field-on="change" />
        </FormField>
        <FormField direction="column" label="Description" marginTop="large" errorMessage={errors.description}>
          <textarea
            name="description"
            type="text"
            value={description}
            maxLength={100}
            {...handlers}
            data-validate-field-on="change"
            style={{ height: 80, resize: 'none', width: '100%' }}
          />
        </FormField>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          loading={updating}
          onClick={() => updateResourceDetail(entityState, props, updateFn)}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Update
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditResourceDetailModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  resourceId: PropTypes.string,
}

EditResourceDetailModal.defaultProps = {
  modalKey: 'EditResourceDetailModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditResourceDetailModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
