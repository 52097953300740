import { useSelector } from 'react-redux'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import * as navigationActions from '@redux/modules/navigation'

const toggleNavigation = (showNavigation, dispatch) => {
  const { changeParam } = navigationActions
  return dispatch(changeParam('showNavigation', !showNavigation))
}

function useMainNavigation(){
  const dispatch = useThunkDispatch()

  const showNavigation = useSelector(reduxState => reduxState.navigation.showNavigation)

  return {
    callbacks: {
      toggleNavigation: () => toggleNavigation(showNavigation, dispatch),
    },
    showNavigation,
  }
}

export default useMainNavigation
