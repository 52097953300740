import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import prestigeServices from '@functions/prestigeServices'

import {
  Box,
  Button,
  DashboardModule,
  FormField,
  ListItem,
  LoadingModule,
  Tag,
  Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'
import useWorkflow from '@hooks/useWorkflow'
import { getDeadline } from '@functions/getDeadline'
import { getEntityByName } from '@functions/getEntityByName'

import Icons from '@components/Icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellOn } from '@fortawesome/pro-light-svg-icons'

const loadWorkflow = (workflow, loadFn, setState) => {
  loadFn(workflow).then(({ success, data }) => {
    if (!success && data){
      toast.warning(data[0])
      return
    }

    setState({ workflow: data })
  })
}

const defaultState = {
  isWorkflowCommentValid: true,
  statusId: 0,
  tagExists: false,
  tags: ['tag 1', 'tag 2'],
  tagValue: '',
  workflow: {},
  workflowComment: '',
}

const JobDetails = (props) => {
  const { showStudioDeadline, showTags, hidden } = props

  const {
    callbacks: { assignServiceJobStatus },
    serviceJob,
    workflowUpdated,
  } = useContext(PageContext)
  const { status } = serviceJob

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const [state, setState] = useSetState(defaultState)
  const {
    isWorkflowCommentValid, statusId, tagExists, tags, tagValue, workflow, workflowComment,
  } = state

  const { currentUser: { timeZone } } = useCurrentUser()

  const serviceJobPayload = useServiceJob({ id: serviceJob.id })
  const {
    callbacks: { assignServiceJobStatus: createFn },
  } = serviceJobPayload

  useEffect(() => {
    setState({ statusId: serviceJob.serviceJobStatusId })
  }, [serviceJob, workflowUpdated])

  const workflowPayload = useWorkflow()
  const {
    callbacks: { loadWorkflow: loadFn },
    loaded,
    loading,
  } = workflowPayload

  useEffect(() => {
    loadWorkflow({ id: 1 }, loadFn, setState)
  }, [loaded])

  const saveServiceJobState = () => {
    setState({ isWorkflowCommentValid: workflowComment.length > 0 })

    if (workflowComment.length > 0){
      const serviceJobParam = {
        id: serviceJob.id,
        serviceJobStatusId: statusId,
        workflowComment,
      }
      assignServiceJobStatus(serviceJobParam, createFn)
    }
  }

  const archiveServiceJob = (archive) => {
    const { histories } = serviceJob
    const reversedHistories = histories.sort((x, y) => y.id - x.id)

    const previousStatus = reversedHistories.find(x => (
      x.serviceJobStatusId !== serviceJob.serviceJobStatusId && x.serviceJobStatusId !== getEntityByName(serviceJobStatuses, 'Archived')?.id
    ))

    const statusBeforeArchived = previousStatus ? previousStatus.serviceJobStatusId : getEntityByName(serviceJobStatuses, 'Ordered')?.id

    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: archive ? getEntityByName(serviceJobStatuses, 'Archived')?.id : statusBeforeArchived,
      workflowComment: `${archive ? 'Archived' : 'Unarchived'} on service job page`,
    }

    swal.fire({
      title: `${archive ? 'Archive' : 'Unarchive'}`,
      html: `This action will ${archive ? 'archive' : 'unarchive'} this job.`
        + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        assignServiceJobStatus(serviceJobParam, createFn)
      }
    })
  }

  const selectTag = (tag) => {
    setState({ tagValue: tag })
  }

  const toggleTag = (tag) => {
    setState({
      tags: tagExists ? tags.filter(x => x !== tagValue) : [...tags, tag],
      tagExists: !tagExists,
    })
  }

  useEffect(() => {
    setState({ tagExists: tags.find(tag => tag === tagValue) })
  }, [tagValue])

  useEffect(() => {
    if (workflow.serviceJobStatuses
      && workflow.serviceJobStatuses.find(e => e.id === 60)
      && prestigeServices.find(x => x.id === serviceJob.service?.id)
      && serviceJob.serviceJobStatusId !== 60){
      workflow.serviceJobStatuses = workflow.serviceJobStatuses.filter(item => item.id !== 60)
      setState({ workflow })
    } else if (prestigeServices.find(x => x.id === serviceJob.service?.id) && statusId === 60 && Object.keys(serviceJobStatuses).length > 0){
      workflow.serviceJobStatuses = Object.values(serviceJobStatuses)
      setState({ workflow })
    }
  }, [statusId, serviceJobStatuses])

  if (hidden) return null

  const clientDeadline = DateTime.fromISO(serviceJob.clientDeadline, { zone: timeZone })
  const studioDeadline = DateTime.fromISO(serviceJob.studioDeadline, { zone: timeZone })

  return (
    <>
      <LoadingModule loading={loading} />
      {!loading && (
        <DashboardModule title="Job Details">
          <Box flexDirection="column">
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              style={{ cursor: 'pointer' }}
              disableHover
            >
              {showStudioDeadline && (
                <Box flexDirection="column" paddingBottom="large">
                  <Box flexDirection="row" paddingBottom="small">
                    <Text fontSize="small" paddingRight="small">
                      Studio Deadline
                    </Text>
                    <FontAwesomeIcon icon={faBellOn} color="#B62121" />
                  </Box>
                  <Button backgroundColor="#FA6F6F" color="white" border={0} size="small">
                    {`${studioDeadline.toFormat('hh:mm a')}, ${studioDeadline.toFormat('dd LLLL y')}`}
                  </Button>
                </Box>
              )}
              <Box flexDirection="column" paddingBottom="large">
                <Box flexDirection="row" paddingBottom="small">
                  <Text fontSize="small" paddingRight="small">
                    Client Deadline
                  </Text>
                  <FontAwesomeIcon icon={faBellOn} color="#B62121" />
                </Box>
                <Button backgroundColor="#FA6F6F" color="white" border={0} size="small">
                  {`${clientDeadline.toFormat('hh:mm a')}, ${clientDeadline.toFormat('dd LLLL y')}`}
                </Button>
                <Button backgroundColor="#FA6F6F" color="white" border={0} size="small" marginTop="small">
                  {getDeadline(serviceJob?.clientDeadline, timeZone)}
                </Button>
              </Box>
              {showTags && (
                <Box flexDirection="column" paddingBottom="large">
                  <Text fontSize="small" paddingBottom="small">
                    Tags
                  </Text>
                  <FormField direction="column">
                    <Box>
                      <input value={tagValue} onChange={e => setState({ tagValue: e.target.value })} type="text" />
                      <Button
                        buttonStyle={tagExists ? 'secondaryDestroy' : 'secondaryCreate'}
                        onClick={() => toggleTag(tagValue)}
                        marginLeft="small"
                        size="small"
                        width="auto"
                        disabled={!tagValue}
                      >
                        {tagExists ? 'Remove' : 'Add'}
                      </Button>
                    </Box>
                    <Box flexWrap="wrap" gridGap="small" marginTop="medium">
                      {tags.map(tag => (
                        <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', onClick: () => selectTag(tag) }} key={tag}>
                          {tag}
                        </Tag>
                      ))}
                    </Box>
                  </FormField>
                </Box>
              )}
              <Box flexDirection="column">
                <Text fontSize="small" paddingBottom="small">
                  Status
                </Text>
                <Box flexDirection="column">
                  <FormField>
                    <select onChange={e => setState({ statusId: e.target.value })} value={statusId || status?.id}>
                      {workflow.serviceJobStatuses?.map(serviceJobStatus => (
                        <option key={serviceJobStatus.id} value={serviceJobStatus.id}>
                          {serviceJobStatus?.description}
                        </option>
                      ))}
                    </select>
                  </FormField>
                </Box>
                <Box flexDirection="column" paddingTop="small" paddingBottom="small">
                  <FormField errorMessage={!isWorkflowCommentValid ? 'Required' : ''}>
                    <textarea
                      type="text"
                      onChange={ev => (setState({ workflowComment: ev.target.value.trim() }))}
                      placeholder="Reason required for change"
                      style={{ height: 80, resize: 'vertical' }}
                    />
                  </FormField>
                </Box>
                <Button buttonStyle="secondaryUtility" size="medium" onClick={saveServiceJobState}>
                  Change Status
                </Button>
              </Box>
            </ListItem>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                padding: 'large',
              }}
              flexDirection="column"
              disableHover
            >
              <Box flexDirection="row">
                <Box flexDirection="column" marginRight="small">
                  <Button
                    buttonStyle="secondaryUtility"
                    disabled={serviceJob.serviceJobStatusId === getEntityByName(serviceJobStatuses, 'Archived')?.id}
                    size="medium"
                    onClick={() => archiveServiceJob(1)}
                  >
                    Archive
                  </Button>
                </Box>
                <Box flexDirection="column" width="auto" flexShrink="0">
                  <Button
                    buttonStyle="secondaryUtility"
                    disabled={serviceJob.serviceJobStatusId !== getEntityByName(serviceJobStatuses, 'Archived')?.id}
                    size="medium"
                    onClick={() => archiveServiceJob(0)}
                    height={37.5}
                  >
                    <Icons name="Unarchive" />
                  </Button>
                </Box>
              </Box>
            </ListItem>
          </Box>
        </DashboardModule>
      )}
    </>
  )
}

JobDetails.propTypes = {
  showStudioDeadline: PropTypes.bool,
  showTags: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default JobDetails
