import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import {
  Box, Image, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

const AssetDetails = () => {
  const { callbacks, selectedAsset } = useContext(PageContext)
  const { showAssetUploadVersionModal } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const latestVersion = selectedAsset?.histories?.reduce((x, y) => ((x.version > y.version) ? x : y))

  return (
    <ListItem boxProps={{ padding: 'large' }} onClick={showAssetUploadVersionModal} showAngleIcon>
      <Box flexDirection="column">
        <Box flexDirection="row">
          <Box width={60} marginRight="medium">
            <Image
              borderRadius={5}
              height={45}
              marginRight="medium"
              url={selectedAsset.previewUrl}
              width={60}
            />
          </Box>

          <Box justifyContent="center" flexDirection="column" width="calc(100% - 75px)">
            <Text color="bodyFontLightColor" fontSize="small" marginBottom="small" variant="ellipsis">
              Version {selectedAsset?.version}  {(latestVersion?.version === selectedAsset?.version) ? '(Current)' : ''}
            </Text>
            <StatusBadge
              color={getServiceStatusColor(serviceJobStatuses[selectedAsset.serviceJobStatusId]?.name)}
              ghost
              text={serviceJobStatuses[selectedAsset.serviceJobStatusId]?.description}
            />
          </Box>
        </Box>
      </Box>
    </ListItem>
  )
}

export default AssetDetails
