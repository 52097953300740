import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useReduxAction from '@hooks/useReduxAction'

import {
  Box, Button, Text,
} from '@campaignhub/suit-theme'

import CustomView from './components/CustomView'
import ServiceGroup from './components/ServiceGroup'
import ServiceJobStatuses from './components/ServiceJobStatuses'

const ServiceJobFilter = (props) => {
  const { setJobState, jobState, showCustomView } = props
  const { clientFilter, dateFilter, serviceFilter, statusFilter, userFilter } = jobState

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const options = {
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...dateFilter?.filterStart && { filterStart: dateFilter.filterStart },
    ...dateFilter?.filterEnd && { filterEnd: dateFilter.filterEnd },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
  }

  useReduxAction('serviceJobFilter', 'loadServiceJobCounts', options, [clientFilter, dateFilter, statusFilter, userFilter])

  return (
    <Box
      backgroundColor="white"
      flexDirection="column"
      height="auto"
      width="280px"
      borderRight="1px solid"
      borderColor="lineColor"
    >
      <Box
        borderBottom="1px solid"
        borderColor="lineColor"
        flexDirection="column"
        marginBottom="large"
        padding="large"
      >
        <Text fontSize="small">Filters</Text>
      </Box>
      <Box flexDirection="column" flexShrink="0" paddingX="large">
        {showCustomView && <CustomView setJobState={setJobState} />}
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="medium"
            fontAlign="center"
            marginBottom="medium"
          >
            Service Types
          </Text>
        </Box>
        {Object.values(serviceGroups).map(serviceGroup => (
          <ServiceGroup key={serviceGroup.id} serviceGroup={serviceGroup} setJobState={setJobState} serviceFilter={serviceFilter} />
        ))}
        <ServiceJobStatuses setJobState={setJobState} statusFilter={statusFilter} />
        <Button buttonStyle="ghostUtility" size="small" display="none" onClick={() => setJobState({ serviceFilter: [], statusFilter: [] })}>
          Reset Filters
        </Button>
      </Box>
    </Box>
  )
}

ServiceJobFilter.propTypes = {
  setJobState: PropTypes.func,
  showCustomView: PropTypes.bool,
  jobState: PropTypes.object,
}

export default ServiceJobFilter
