import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'
import { useSelector } from 'react-redux'

import { Box, LoadingModule, TabBar } from '@campaignhub/suit-theme'

import {
  faCalendar, faLayerGroup, faTasks,
} from '@fortawesome/pro-light-svg-icons'

import useReduxAction from '@hooks/useReduxAction'

import useClientResources from '@hooks/useClientResources'
import Assets from './components/Assets'
import Resources from './components/Resources'
import ClientResources from './components/ClientResources'

const defaultState = {
  activeTabBarItemKey: 'assets',
  assetAndResourcesTabs: [],
  selectedIds: [],
  defaultView: true,
}

const AssetsAndResources = (props) => {
  const { hideProof, resourceTypes, serviceJob, showResourcesOnly, workflowUpdated } = props

  const [state, setState] = useSetState(defaultState)
  const { activeTabBarItemKey, assetAndResourcesTabs, selectedIds, defaultView } = state

  const assetIncludes = ['comments', 'file', 'histories']
  useReduxAction('assets', 'loadAssets', {
    serviceJobs: serviceJob.id,
    includes: assetIncludes.join(','),
  }, [serviceJob.id, workflowUpdated])

  useReduxAction('resources', 'loadResources', {
    campaigns: serviceJob?.campaign?.id,
    includes: 'file',
  }, [serviceJob.id])

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources } = entities

  useReduxAction('resourcesCompressRequests', 'loadResourcesCompressRequests',
  { serviceJobId: serviceJob.id }, [Object.keys(resources).length], {
    dispatchAction: (action, requestOptions) => action(requestOptions),
    shouldPerformFn: () => Object.keys(resources).length > 0,
  })

  const { clientResources, loading } = useClientResources(serviceJob.id)

  useEffect(() => {
    setState({ activeTabBarItemKey: 'assets', defaultView: true })
   }, [])

  useEffect(() => {
    const assetTab = {
      icon: faCalendar,
      key: 'assets',
      title: 'Assets',
      onClick: () => setState({ activeTabBarItemKey: 'assets', defaultView: true }),
    }

    const proofTab = {
      icon: faTasks,
      key: 'proofs',
      title: 'Proofs',
      onClick: () => setState({ activeTabBarItemKey: 'proofs' }),
    }

    const resourcesTab = []
    if (resourceTypes.length > 0){
      resourcesTab.push({
        icon: faLayerGroup,
        key: 'resources',
        title: 'Resources',
        onClick: () => setState({ activeTabBarItemKey: 'resources' }),
      })
    }

    resourcesTab.push({
      icon: faLayerGroup,
      key: 'relatedResources',
      title: 'Related',
      onClick: () => setState({ activeTabBarItemKey: 'relatedResources' }),
    })

    if (clientResources && Object.keys(clientResources)?.filter(x => clientResources[x].files.length > 0).length > 0){
      resourcesTab.push({
        icon: faLayerGroup,
        key: 'clientResources',
        title: 'Client',
        onClick: () => setState({ activeTabBarItemKey: 'clientResources' }),
      })
    }

    if (showResourcesOnly){
      setState({ activeTabBarItemKey: 'resources', assetAndResourcesTabs: resourcesTab })
    } else if (hideProof){
      setState({ activeTabBarItemKey: 'assets', defaultView: true, assetAndResourcesTabs: [assetTab, ...resourcesTab] })
    } else if (defaultView){
        setState({ activeTabBarItemKey: 'assets', defaultView: true, assetAndResourcesTabs: [assetTab, proofTab, ...resourcesTab] })
    } else setState({ activeTabBarItemKey, assetAndResourcesTabs: [assetTab, proofTab, ...resourcesTab] })
  }, [showResourcesOnly, hideProof, clientResources, resourceTypes])

  const updateSelectedIds = (updatedIds) => {
    setState({ selectedIds: updatedIds })
  }

  const checkActionStatus = (status) => {
    if (status){
      if (status === 'zip-resources'){
        setState({ selectedIds: [] })
      }
      setState({ defaultView: false })
    }
  }

  return (
    <Box flexDirection="column">
      <LoadingModule loading={loading} />
      {!loading && (
        <>
          <TabBar
            items={assetAndResourcesTabs}
            selectedItemKey={activeTabBarItemKey}
            invertColors
          />
          <Box flexDirection="column" marginBottom="large" variant="white">
            {!['clientResources', 'resources', 'relatedResources'].includes(activeTabBarItemKey) && (
              <Assets
                activeTabBarItemKey={activeTabBarItemKey}
                callbacks={{ toggleUpdateSelectedIds: updatedIds => updateSelectedIds(updatedIds) }}
                selectedIds={selectedIds}
                serviceJob={serviceJob}
              />
            )}
            {['resources', 'relatedResources'].includes(activeTabBarItemKey) && (
              <Resources
                callbacks={{ toggleUpdateSelectedIds: updatedIds => updateSelectedIds(updatedIds), toggleCheckActionStatus: status => checkActionStatus(status) }}
                editable={activeTabBarItemKey === 'resources'}
                selectedIds={selectedIds}
                serviceJob={serviceJob}
                activeTabBar={activeTabBarItemKey}
              />
            )}
            {['clientResources'].includes(activeTabBarItemKey) && (
              <ClientResources resources={clientResources} serviceJob={serviceJob} />
            )}
          </Box>
        </>
      )}

    </Box>
  )
}

AssetsAndResources.propTypes = {
  hideProof: PropTypes.bool,
  resourceTypes: PropTypes.array,
  serviceJob: PropTypes.object,
  showResourcesOnly: PropTypes.bool,
  workflowUpdated: PropTypes.bool,
}

export default AssetsAndResources
