import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Box, LoadingModule, MainContent, ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { getEntityByName } from '@functions/getEntityByName'
import { groupBy } from '@functions/groupBy'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useUploadAssetVersion from '@hooks/useUploadAssetVersion'
import useUsers from '@hooks/useUsers'

import AssetClientModal from '@modals/AssetClientModal'
import AssetEditorModal from '@modals/AssetEditorModal'
import AssetHistoryModal from '@modals/AssetHistoryModal'
import AssetQCModal from '@modals/AssetQCModal'
import AssetSettingsModal from '@modals/AssetSettingsModal'
import UploadVersionModal from '@modals/UploadVersionModal'

import AssetSection from './components/AssetSection'
import Header from './components/Header'

const callbacks = (component, setState, isMobileDevice) => {
  const componentCallbacks = {
    AssetClientModal: {
      closeModal: () => setState({ showAssetClientModal: false }),
    },
    AssetEditorModal: {
      closeModal: () => setState({ showAssetEditorModal: false }),
    },
    AssetHistoryModal: {
      closeModal: () => setState({ showAssetHistoryModal: false }),
    },
    AssetQCModal: {
      closeModal: () => setState({ showAssetQCModal: false }),
    },
    AssetSettingsModal: {
      closeModal: isMobileDevice ? () => setState({ showAssetSettingsModal: false }) : null,
    },
    AssetUploadVersionModal: {
      closeModal: () => setState({ showAssetUploadVersionModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  assignedUserRole: [],
  clientComments: [],
  fullscreen: true,
  groupedAssetComments: [],
  isMobileDevice: false,
  markers: [],
  proofingNotes: [],
  selectedAsset: {},
  selectedAssetVersionCount: 0,
  showAllComments: false,
  showAssetClientModal: false,
  showAssetEditorModal: false,
  showAssetHistoryModal: false,
  showAssetQCModal: false,
  showAssetSettingsModal: false,
  showAssetUploadVersionModal: false,
  showMarkers: true,
}

const FullAssetScreen = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    assignedUserRole,
    clientComments,
    fullscreen,
    groupedAssetComments,
    isMobileDevice,
    markers,
    proofingNotes,
    selectedAsset,
    selectedAssetVersionCount,
    showAllComments,
    showAssetClientModal,
    showAssetEditorModal,
    showAssetHistoryModal,
    showAssetQCModal,
    showAssetSettingsModal,
    showAssetUploadVersionModal,
    showMarkers,
  } = state

  const { id: serviceJobId } = useParams()

  const [searchParams] = useSearchParams()
  const assetId = parseInt(searchParams.get('assetId'), 10)

  const { currentUser } = useCurrentUser()
  const { users } = useUsers()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const serviceJobIncludes = ['service', 'campaign', 'client', 'details', 'histories', 'postProductionChecklistItems', 'users']
  useReduxAction('serviceJobs', 'loadServiceJobV1', {
    includes: serviceJobIncludes.join(','),
  }, [serviceJobId], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && !loadedIds.includes(serviceJobId)
    },
  })

  const assetIncludes = ['comments.createdBy', 'histories.createdBy']
  useReduxAction('assets', 'loadAssets', {
    serviceJobs: serviceJobId,
    includes: assetIncludes.join(','),
  }, [serviceJobId, assetId])

  const { serviceJob } = useServiceJob({ id: serviceJobId })
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)

  const useUploadAssetVersionPayload = useUploadAssetVersion()
  const { callbacks: { loadAsset: loadFnAsset } } = useUploadAssetVersionPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, assetComments, assetCommentTypes } = entities

  const copyFileAsset = Object.values(assets).find(x => x.serviceJobId === parseInt(serviceJobId, 10) && x.file !== null)

  const { loading } = useSelector(reduxState => reduxState.assets)

  useEffect(() => {
    setState({ selectedAsset: assets[assetId] })
  }, [assetId, assets])

  useEffect(() => {
    setState({ assignedUserRole: filteredServiceJobUsers?.filter(x => x.user.id === currentUser.id) })
  }, [serviceJobId, filteredServiceJobUsers])

  useEffect(() => {
    const comments = Object.values(assetComments).filter(x => x.assetId === selectedAsset?.id)
    if (selectedAsset && Object.keys(selectedAsset)?.length){
      const markerFilter = comments?.reduce((filtered, comment) => {
        if (comment.details?.some(x => x.entityFieldType?.name === 'Position')){
          filtered.push({
            ...comment,
            left: JSON.parse(comment.details[0]?.value).x,
            top: JSON.parse(comment.details[0]?.value).y,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty details array */

      const clientFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id){
          filtered.push({
            ...comment,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, [])

      const proofingFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ProofingNote')?.id){
          filtered.push({
            ...comment,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, [])

      const versions = [...new Map(selectedAsset.histories?.map(item => [item.version, item.version])).values()]

      setState({
        clientComments: clientFilter ? [...clientFilter] : [],
        markers: markerFilter ? [...markerFilter] : [],
        proofingNotes: proofingFilter ? [...proofingFilter] : [],
        selectedAssetVersionCount: versions.length,
      })
    }
  }, [selectedAsset, assetComments])

  useEffect(() => {
    setState({ groupedAssetComments: groupBy(markers, 'status') })
  }, [markers])

  const pageContext = {
    assignedUserRole,
    currentUser,
    callbacks: {
      showAssetClientModal: (payload) => {
        setModalData('AssetClientModal', payload)
        setState({ showAssetClientModal: true })
      },
      showAssetEditorModal: (payload) => {
        setModalData('AssetEditorModal', payload)
        setState({ showAssetEditorModal: true })
      },
      showAssetHistoryModal: (payload) => {
        setModalData('AssetHistoryModal', payload)
        setState({ showAssetHistoryModal: true })
      },
      showAssetQCModal: (payload) => {
        setModalData('AssetQCModal', payload)
        setState({ showAssetQCModal: true })
      },
      showAssetSettingsModal: (payload) => {
        setModalData('AssetSettingsModal', payload)
        setState({ showAssetSettingsModal: true })
      },
      showAssetUploadVersionModal: (payload) => {
        setModalData('AssetUploadVersionModal', payload)
        setState({ showAssetUploadVersionModal: true })
      },
      toggleShowMarkers: () => setState({ showMarkers: !showMarkers }),
      toggleFullScreen: () => setState({ fullscreen: !fullscreen, showAssetSettingsModal: !showAssetSettingsModal }),
      toggleShowAllComments: () => setState({ showAllComments: !showAllComments }),
    },
    clientComments,
    histories: copyFileAsset?.histories || [],
    fullscreen,
    groupedAssetComments,
    isMobileDevice,
    markerSetState: setState,
    markers,
    proofingNotes,
    selectedAsset,
    selectedAssetVersionCount,
    serviceJob,
    showAllComments,
    showMarkers,
    users,
  }

  useEffect(() => {
    if (fullscreen){
      setState({
        showAssetClientModal: false,
        showAssetEditorModal: false,
        showAssetHistoryModal: false,
        showAssetQCModal: false,
      })
    }
  }, [fullscreen])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <MainContent
          offset={{ left: 0, top: 71 }}
          width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
          style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
        >
          <Header assetPayload={state} />

          {(loading || !assetId) && (
            <Box flexDirection="column" marginTop="large" paddingX="large">
              <LoadingModule loading={loading || !assetId} times={3} />
            </Box>
          )}

          {(!loading || !assetId) && <AssetSection />}
        </MainContent>
        <AssetClientModal
          callbacks={callbacks('AssetClientModal', setState)}
          showModal={showAssetClientModal}
        />
        <AssetEditorModal
          callbacks={callbacks('AssetEditorModal', setState)}
          showModal={showAssetEditorModal}
        />
        <AssetHistoryModal
          callbacks={callbacks('AssetHistoryModal', setState)}
          showModal={showAssetHistoryModal}
        />
        <AssetQCModal
          callbacks={callbacks('AssetQCModal', setState, null)}
          showModal={showAssetQCModal}
        />
        <AssetSettingsModal
          callbacks={callbacks('AssetSettingsModal', setState, isMobileDevice)}
          disableAnimation
          disableOverlay
          showModal={showAssetSettingsModal}
        />
        <UploadVersionModal
          callbacks={{
            ...callbacks('AssetUploadVersionModal', setState, null),
            loadFn: loadFnAsset,
          }}
          showModal={showAssetUploadVersionModal}
          title="Upload"
          titleSecondLine="Assets"
          serviceJobOptions={{ id: selectedAsset?.id, ServiceJobId: serviceJob.id }}
          type="Assets"
          selectedAsset={selectedAsset}
          assetScreenSetState={setState}
          displayAllowedFileTypes
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default FullAssetScreen
