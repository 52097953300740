import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from '@campaignhub/suit-theme'

const FilterByLimit = (props) => {
  const { setJobState, jobState } = props
  const { filterLimit } = jobState

  return (
    <FormField direction="column" label="Filter By Limit" boxProps={{ paddingBottom: 'medium' }}>
      <select onChange={e => setJobState({ filterLimit: e.target.value })} value={filterLimit}>
        <option value={50}>50</option>
        <option value={100}>100</option>
        <option value={150}>150</option>
        <option value={200}>200</option>
      </select>
    </FormField>
  )
}

FilterByLimit.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default FilterByLimit
