import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Text, ListItem } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  serviceState: [],
}

const ServiceGroup = (props) => {
  const { serviceGroup, setJobState, serviceFilter } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { services } = entities

  const [state, setState] = useSetState(defaultState)
  const { expanded, serviceState } = state

  useEffect(() => {
    setState({
      serviceState: serviceGroup.services.map(service => ({
        id: service,
        name: services[service]?.name,
        jobCount: services[service]?.jobCount,
        isChecked: serviceFilter.includes(service),
      })),
    })
  }, [serviceFilter, services])

  const updateFilterState = (checked, serviceId) => {
    setState({
      serviceState:
        serviceState.map((data) => {
          if (serviceId === data.id){
            data.isChecked = checked
            if (checked){ setJobState({ serviceFilter: [...serviceFilter, serviceId] }) } else {
              setJobState({ serviceFilter: serviceFilter.filter(i => i !== data.id) })
            }
          }
          return data
        }),
    })
  }

  return (
    <ListItem
      boxProps={{
        padding: 'medium',
      }}
      flexDirection="column"
      disableHover
      style={{ cursor: 'pointer' }}
    >
      <Box flexDirection="row" onClick={() => setState({ expanded: !expanded })}>
        <Box
          color="bodyFontLightColor"
          marginLeft="auto"
          width="auto"
          marginRight="medium"
        >
          <Icons name={serviceGroup.name.replace(/\s/g, '').replace('-', '')} />
        </Box>
        <Box flexDirection="column">
          <Text
            color="bodyFontLightColor"
            fontSize="small"
            fontWeight="medium"
            fontAlign="center"
            marginBottom="large"
          >
            {serviceGroup.name}
          </Text>
        </Box>
        <Box
          color="bodyFontLightColor"
          marginLeft="medium"
          width="auto"
          marginRight="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {expanded && (
        <Box flexDirection="column" alignItems="center" lineHeight="1.5">
          {serviceState.map(service => (
            <Box key={service.id} flexDirection="row">
              <Box
                color="bodyFontLightColor"
                marginLeft="auto"
                width="auto"
                marginRight="medium"
              >
                <input
                  onChange={e => updateFilterState(e.target.checked, service.id)}
                  type="checkbox"
                  checked={service.isChecked}
                  className={styles.checkbox}
                />
              </Box>
              <Box flexDirection="column">
                <Text
                  color="bodyFontLightColor"
                  fontSize="small"
                  marginBottom="medium"
                  marginRight="medium"
                >
                  {service.name}
                </Text>
              </Box>
              <Box flexDirection="column" width="auto">
                <StatusBadge color="gray" text={service.jobCount ?? 0} />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </ListItem>
  )
}

ServiceGroup.propTypes = {
  serviceGroup: PropTypes.object,
  setJobState: PropTypes.func,
  serviceFilter: PropTypes.array,
}

export default ServiceGroup
