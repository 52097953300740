/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useMemo } from 'react'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { getUserInfo } from '@functions/staticWebAppUserInfo'
import { baseURL } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    requestOptions = {},
    maxFileSize,
    callbacks,
    fieldName,
    endpoint,
    loadSearchParams,
    validateFileCount,
  } = options

  let errorList = []
  let selectedFiles = []
  const uploadedList = []

  const { addRecentlyUploadedBatch, doneUpload, loadFn, setFilesToUploadCount } = callbacks

  const onComplete = async () => {
    let counter = uploadedList.length
    const newList = []

    function updateList(){
      counter -= 1
      uploadedList.splice(0, uploadedList.length)
      if (counter === 0){
        addRecentlyUploadedBatch(newList, errorList)
        doneUpload(true)
      }
    }

    async function addToNewList(id, callback){
      const promise = await loadFn(id, new URLSearchParams(loadSearchParams))
      newList.push(promise.result)
      callback()
    }

    await uploadedList.forEach(async (id) => {
      addToNewList(id, updateList)
    })

    if (!uploadedList.length && errorList.length){
      addRecentlyUploadedBatch(newList, errorList)
      doneUpload(true)
    }
  }

  const validateItemCounts = async (files, uppy) => {
    if (Object.keys(files).length === parseInt(validateFileCount, 10)){
      uppy.upload()
    } else {
      swal.fire({
        title: 'Number of Resources',
        text: `Selected images do not match the number of images for upload. Would you like to proceed with ${Object.keys(files).length}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e2001a',
      }).then(({ value }) => {
        if (value){
          uppy.upload().then(() => {
            setFilesToUploadCount(Object.keys(files).length)
          })
        } else {
          errorList = []
          uppy.reset()
        }
      })
    }
    selectedFiles = []
  }

  const swaKey = Buffer.from(JSON.stringify(getUserInfo())).toString('base64')

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: false,
    method: 'post',
    restrictions: {
      allowedFileTypes,
      maxFileSize,
    },
    allowMultipleUploadBatches: !parseInt(validateFileCount, 10),
    onBeforeFileAdded: (currentFile) => {
      selectedFiles.push(currentFile)
    },
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/${endpoint}`,
      method: 'POST',
      headers: {
        'x-ms-client-principal': swaKey,
      },
      formData: true,
      fieldName,
      timeout: 0,
      getResponseError(){ },
    })
    .on('restriction-failed', (file, error) => {
      if (error.message === 'Cannot add more files'){
        toast.warning(error.message)
      } else {
        errorList.push({ ...file, errorType: 'restricted', errorMessage: error.message })
      }
    })
    .on('files-added', async (files) => {
      Object.values(files).map((file) => {
        uppy.setFileMeta(file.id, {
          ...requestOptions,
        })
      })

      if (validateFileCount){
        await validateItemCounts(selectedFiles, uppy)
      } else {
        uppy.upload()
      }
    })
    .on('upload-success', (file, response) => {
      uploadedList.push(response.body)
    })
    .on('upload-error', (file) => {
      errorList.push({ ...file, errorType: 'failed', errorMessage: 'Failed to Upload' })
    })
    .on('complete', async () => {
      await onComplete()
      uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions']
  const optionKeys = Object.keys(options)

  return requiredOptions.every(key => optionKeys.includes(key))
}

function useUppyUpload(options = {}){
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUpload
