import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import { Box, Link, ListItem, Text, StatusBadge } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

const HistoryTab = (props) => {
  const { histories, serviceJob } = props
  const { currentUser: { timeZone } } = useCurrentUser()
  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  return (
    <Box flexDirection="column" alignItems="center" paddingX="large" width="100%">
      {histories?.sort((a, b) => (a.id > b.id ? -1 : 1)).map(history => (
        <ListItem
          boxProps={{
            alignItems: 'center',
            paddingY: 'small',
            borderBottom: '1px dashed',
          }}
          flexDirection="row"
          disableHover
          key={history.id}
        >
          <Box flexDirection="row" justifyContent="space-between" paddingBottom="small">
            <Box flexDirection="column" marginY="medium">
              <Text fontSize="small" fontWeight="500" marginBottom="small">
                {DateTime.fromISO(history.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
              </Text>
              <StatusBadge
                color={getServiceStatusColor(serviceJobStatuses[serviceJob.serviceJobStatusId]?.name)}
                text={serviceJobStatuses[history.serviceJobStatusId]?.description}
              />
              <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="small">
                {history.createdBy.firstName} {history.createdBy.lastName}
              </Text>
            </Box>
            <Box color="bodyFontLightColor" marginRight="medium" width="auto" alignItems="center">
              <Link href={history.assetUrl}>
                <FontAwesomeIcon icon={faDownload} />
              </Link>
            </Box>
          </Box>
        </ListItem>
      ))}
      {!histories?.length && (
        <Box paddingY="large" justifyContent="center">
          <Text fontSize="xsmall" color="bodyFontLightColor">No history available</Text>
        </Box>
      )}
    </Box>
  )
}

HistoryTab.propTypes = {
  histories: PropTypes.array,
  serviceJob: PropTypes.object,
}

export default HistoryTab
