/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Box, Columns, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import UploadVersionModal from '@modals/UploadVersionModal'
import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJob from '@hooks/useServiceJob'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useUploadAsset from '@hooks/useUploadAsset'
import useUploadAssetVersion from '@hooks/useUploadAssetVersion'
import useUsers from '@hooks/useUsers'

import { saveDocxFile } from '@functions/copyDocxFile'
import { getEntityByName } from '@functions/getEntityByName'
import { groupBy } from '@functions/groupBy'

import custom from '@styles/custom.module.scss'

import AssetMarker from './components/AssetMarker'
import ClientTab from './components/ClientTab'
import CopyTemplate from './components/CopyTemplate'
import EditorTab from './components/EditorTab'
import HistoryTab from './components/HistoryTab'
import QCTab from './components/QCTab'
import ToolBar from './components/ToolBar'

const updateAsset = (assetParams, updateFn, createFn, saveDocxParams, saveDocx, setState) => {
  setState({ copyFieldDisabled: true })
  updateFn(assetParams).then(({ success, errors }) => {
    if (!success && errors){
      return toast.warning(errors[0])
    }
    if (saveDocx){
      const { serviceJob, details, updatedCopyDetails, assetFile } = saveDocxParams
      const updateAssetCallback = () => setState({ updatedAsset: true })
      saveDocxFile(serviceJob, details, updatedCopyDetails, createFn, updateFn, assetFile, updateAssetCallback, true)
    }
  })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    AssetUploadVersionModal: {
      closeModal: () => setState({ showAssetUploadVersionModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  assignedUserRole: [],
  clientComments: [],
  copyFieldDisabled: true,
  groupedAssetComments: [],
  markers: [],
  proofingNotes: [],
  selectedAsset: {},
  selectedAssetVersionCount: 0,
  showAllMarkers: false,
  showAssetUploadVersionModal: false,
  showClient: false,
  showEditor: true,
  showHistory: false,
  showQc: false,
  updatedAsset: false,
  updatedCopyDetails: [],
}

const AssetScreen = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    assignedUserRole, clientComments, copyFieldDisabled, groupedAssetComments, markers, proofingNotes, selectedAsset, selectedAssetVersionCount,
    showAllMarkers, showAssetUploadVersionModal, showClient, showEditor, showHistory, showQc, updatedAsset, updatedCopyDetails,
  } = state

  const { id: serviceJobId } = useParams()

  const [searchParams] = useSearchParams()
  const assetId = parseInt(searchParams.get('assetId'), 10)

  const navigate = useNavigate()

  const { currentUser } = useCurrentUser()
  const { users } = useUsers()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, assetComments, assetCommentTypes, serviceGroups, serviceJobDetails, serviceJobStatuses, wordReplacements } = entities

  const copyFileAsset = Object.values(assets).find(x => x.serviceJobId === parseInt(serviceJobId, 10) && x.file !== null)

  const serviceJobIncludes = ['service', 'campaign', 'client', 'details', 'histories', 'postProductionChecklistItems', 'users']
  useReduxAction('serviceJobs', 'loadServiceJobV1', {
    includes: serviceJobIncludes.join(','),
  }, [serviceJobId], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && !loadedIds.includes(serviceJobId)
    },
  })

  const assetIncludes = ['comments.createdBy', 'histories.createdBy']
  useReduxAction('assets', 'loadAssets', {
    serviceJobs: serviceJobId,
    includes: assetIncludes.join(','),
  }, [serviceJobId, updatedAsset])

  const { serviceJob } = useServiceJob({ id: serviceJobId })
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)
  const modalRequestOptions = { id: selectedAsset?.id, ServiceJobId: serviceJob.id }

  const details = Object.values(serviceJobDetails).filter(x => serviceJob?.details?.includes(x.id))
  const productionChecklist = details.filter(x => x.entityFieldType.name === 'PostProductionChecklist')

  useEffect(() => {
    if (serviceJob.serviceJobStatusId === getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id){
      setState({ showClient: true, showEditor: false, showQc: false, showHistory: false })
    }
  }, [serviceJobId])

  useEffect(() => {
    setState({ assignedUserRole: filteredServiceJobUsers?.filter(x => x.user.id === currentUser.id) })
  }, [serviceJobId, filteredServiceJobUsers])

  useEffect(() => {
    setState({
      selectedAsset: assets[(updatedAsset || showAssetUploadVersionModal) ? selectedAsset?.id : assetId],
      updatedCopyDetails: [],
    })
    if (updatedAsset) setState({ updatedAsset: false })
  }, [serviceJobId, assetId, assets])

  useEffect(() => {
    const comments = Object.values(assetComments).filter(x => x.assetId === selectedAsset?.id)
    if (selectedAsset && Object.keys(selectedAsset)?.length){
      const markerFilter = comments?.reduce((filtered, comment) => {
        if (comment.details?.some(x => x.entityFieldType?.name === 'Position')){
          filtered.push({
            ...comment,
            left: JSON.parse(comment.details[0]?.value).x,
            top: JSON.parse(comment.details[0]?.value).y,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty details array */
      const clientFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id){
          filtered.push({
            ...comment,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, [])
      const proofingFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ProofingNote')?.id){
          filtered.push({
            ...comment,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, [])
      const versions = [...new Map(selectedAsset.histories?.map(item => [item.version, item.version])).values()]
      setState({
        markers: markerFilter ? [...markerFilter] : [],
        clientComments: clientFilter ? [...clientFilter] : [],
        proofingNotes: proofingFilter ? [...proofingFilter] : [],
        selectedAssetVersionCount: versions.length,
      })
    }
  }, [selectedAsset, assetComments])

  useEffect(() => {
    setState({ groupedAssetComments: groupBy(markers, 'status') })
  }, [markers])

  const componentMapping = {
    AssetMarker,
    CopyTemplate,
    DefaultView: () => (
      <Box
        alignItems="center"
        display="grid"
        height={[150, 250, 650]}
        justifyContent="center"
        variant="white"
      >
        <Text fontSize="small" fontWeight="600">No Available View</Text>
      </Box>
    ),
  }

  const useUploadAssetPayload = useUploadAsset()
  const { callbacks: {
    createAsset: createFnAsset,
    updateAsset: updateFnAsset,
  } } = useUploadAssetPayload

  const useUploadAssetVersionPayload = useUploadAssetVersion()
  const { callbacks: {
    loadAsset: loadFnAsset,
  } } = useUploadAssetVersionPayload

  const updateCopyDetails = (asset, copyDetailId, value) => {
    const assetToUpdate = asset

    const { copy } = assetToUpdate
    const copyToUpdate = copy.copyDetails.find(x => x.id === copyDetailId)
    copyToUpdate.value = value

    const temporaryAssets = updatedCopyDetails
    const assetIndex = temporaryAssets.findIndex(x => x.id === asset.id)
    if (assetIndex >= 0){
      temporaryAssets[assetIndex] = assetToUpdate
    } else {
      temporaryAssets.push(assetToUpdate)
    }

    setState({ updatedCopyDetails: temporaryAssets })
  }

  const saveCopyChanges = (type) => {
    const assetFile = Object.values(assets).find(asset => asset.serviceJobId === serviceJob.id && asset.file)

    if (type === 'scan'){
      updatedCopyDetails.map((asset) => {
        asset.copy.copyDetails.map((x) => {
          let replaced = x.value

          Object.values(wordReplacements).map((word) => {
            const re = new RegExp(`(?<!\\w)(${word.input})(?!\\w)`, 'g')

            replaced = replaced.replace(re, word.output)
          })

          if (replaced !== x.value){
            updateCopyDetails(asset, x.id, replaced)
          }
        })
      })
    }

    updatedCopyDetails.map((asset, index) => {
      const formData = new FormData()
      formData.append('Description', asset.description)
      formData.append('CopyTemplateId', asset.copy.copyTemplateId)
      formData.append('CopyDetails', JSON.stringify(asset.copy.copyDetails))

      const assetParams = {
        id: asset.id,
        data: formData,
      }

      const filledCopies = Object.values(assets).filter(x => x.copy?.copyDetails.some(y => y.value !== null && y.value !== ''))

      const saveDocxParams = {
        serviceJob,
        details,
        updatedCopyDetails: [...updatedCopyDetails, ...filledCopies.filter(x => !updatedCopyDetails?.map(y => y.id).includes(x.id))],
        assetFile,
      }

      updateAsset(assetParams, updateFnAsset, createFnAsset, saveDocxParams, updatedCopyDetails.length === index + 1, setState)
    })
  }

  const getAssetComponent = () => {
    switch (serviceGroups[serviceJob?.serviceGroupId]?.id){
      case 1: case 2:
        return ({
          component: componentMapping.AssetMarker,
          componentProps: {
            contents: Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id),
            markers,
            showAllMarkers,
            setState,
          },
        })
      case 3:
        return ({
          component: componentMapping.CopyTemplate,
          componentProps: {
            callbacks: {
              toggleCopyFieldState: () => { setState({ copyFieldDisabled: !copyFieldDisabled }) },
              toggleUpdateCopyDetails: (asset, copyDetailId, value) => updateCopyDetails(asset, copyDetailId, value),
            },
            contents: Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id && asset.copy?.id),
            copyFieldDisabled,
          },
        })
      default: return ({ component: componentMapping.DefaultView })
    }
  }

  const { component: Component, componentProps } = getAssetComponent()

  const pageContext = {
    assignedUserRole,
    callbacks: {
      showAssetUploadVersionModal: (payload) => {
        setModalData('UploadVersionModal', payload)
        setState({ showAssetUploadVersionModal: true })
      },
    },
    clientComments,
    currentUser,
    groupedAssetComments,
    proofingNotes,
    selectedAsset,
    selectedAssetVersionCount,
    serviceJob,
    serviceJobId: parseInt(serviceJobId, 10),
    users,
  }

  return (
    <PageContext.Provider value={pageContext}>
      <Box display="flex" flexDirection="column" minWidth="100%" backgroundColor="white">
        <Columns flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Box flexDirection="column" padding="large" paddingRight={[16, 16, 0]}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                marginRight="auto"
                onClick={() => navigate(-1)}
                style={{ cursor: 'pointer' }}
                width="auto"
                paddingBottom="medium"
              >
                <Box paddingRight="medium" marginRight="auto" justifyContent="flexEnd" width="auto">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Box>
                <Text fontSize="xsmall" color="bodyFontLightColor">
                  Go Back
                </Text>
              </Box>
              <Component componentProps={componentProps} serviceJob={serviceJob} />
            </Box>
          </Columns.Main>
          <Columns.Sidebar>
            <Box borderRadius="0 !important" flexDirection="column" lineHeight="1.3" variant="white" className={custom.scroll}>
              <Box alignItems="center" display="flex" flexDirection="row" width="100%">
                <Box
                  padding="large"
                  justifyContent="center"
                  borderBottom={showEditor ? 'solid 3px #E95B35' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setState({ showClient: false, showEditor: true, showQc: false, showHistory: false })}
                >
                  <Text fontSize="small" color={showEditor ? '#E95B35' : 'bodyFontColor'}>
                    Editor
                  </Text>
                </Box>
                {!['Copywriting'].includes(serviceGroups[serviceJob?.serviceGroupId]?.name) ? (
                  <Box
                    padding="large"
                    justifyContent="center"
                    borderBottom={showQc ? 'solid 3px #E95B35' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setState({ showClient: false, showEditor: false, showQc: true, showHistory: false })}
                  >
                    <Text fontSize="small" color={showQc ? '#E95B35' : 'bodyFontColor'}>
                      QC
                    </Text>
                  </Box>
                ) : (
                  <Box
                    padding="large"
                    justifyContent="center"
                    borderBottom={showHistory ? 'solid 3px #E95B35' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setState({ showClient: false, showEditor: false, showQc: false, showHistory: true })}
                  >
                    <Text fontSize="small" color={showHistory ? '#E95B35' : 'bodyFontColor'}>
                      History
                    </Text>
                  </Box>
                )}
                <Box
                  padding="large"
                  justifyContent="center"
                  borderBottom={showClient ? 'solid 3px #E95B35' : ''}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setState({ showClient: true, showEditor: false, showQc: false, showHistory: false })}
                >
                  <Text fontSize="small" color={showClient ? '#E95B35' : 'bodyFontColor'}>
                    Client
                  </Text>
                </Box>
              </Box>
              <Box height="83vh" overflowY="auto">
                {showClient && <ClientTab />}
                {showEditor && <EditorTab productionChecklist={productionChecklist} />}
                {showHistory && <HistoryTab histories={copyFileAsset?.histories || []} serviceJob={serviceJob} />}
                {showQc && <QCTab />}
              </Box>
            </Box>
          </Columns.Sidebar>
          <UploadVersionModal
            callbacks={{
              ...callbacks('AssetUploadVersionModal', null, setState),
              loadFn: loadFnAsset,
            }}
            showModal={showAssetUploadVersionModal}
            title="Upload"
            titleSecondLine="Assets"
            serviceJobOptions={modalRequestOptions}
            type="Assets"
            selectedAsset={selectedAsset}
            assetScreenSetState={setState}
            displayAllowedFileTypes
          />
        </Columns>
        <ToolBar
          callbacks={{
            toggleSaveCopy: type => saveCopyChanges(type),
            toggleShowAllMarkers: () => { setState({ showAllMarkers: !showAllMarkers }) },
          }}
          copyFieldDisabled={copyFieldDisabled}
          showAllMarkers={showAllMarkers}
          showMarkerToggle={['Photography', 'Floorplan'].includes(serviceGroups[serviceJob?.serviceGroupId]?.name)}
          showSaveCopy={serviceGroups[serviceJob?.serviceGroupId]?.name === 'Copywriting'}
        />
      </Box>

    </PageContext.Provider>
  )
}

export default AssetScreen
