/* eslint-disable array-callback-return */
import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Button, ModalContext, SidebarModal, Box, ListItem,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'
import WordReplacement from './components/WordReplacement'

const createWordReplacement = (entityParams, createFn) => {
  createFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const deleteWordReplacement = (entityParams, deleteFn) => {
  deleteFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const updateWordReplacement = (entityParams, updateFn) => {
  updateFn(entityParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warn(errors[0])
    }
  })
}

const defaultState = {
  replacementList: [],
}

const CreateOrEditWordReplacementsModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { replacementList } = state

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: {
      createWordReplacement: createFn,
      deleteWordReplacement: deleteFn,
      updateWordReplacement: updateFn,
    },
  } = modalPayload

  const { wordReplacements } = useSelector(reduxState => reduxState.entities)
  const { creating, deleting, updating } = useSelector(reduxState => reduxState.wordReplacements)

  useEffect(() => {
    setState({ replacementList: cloneDeep(Object.values(wordReplacements))
      .sort((a, b) => (a.input.toLowerCase() > b.input.toLowerCase() ? 1 : -1))
      .map((replacement, index) => ({
        ...replacement,
        sort: index,
      })),
    })
  }, [wordReplacements])

  const addWord = () => {
    const newList = replacementList
    const tempId = newList.length ? newList[newList.length - 1].id + 1 : 1

    newList.push({
      id: tempId,
      input: '',
      output: '',
      sort: tempId,
    })

    setState({ replacementList: newList })
  }

  const removeWord = (wordReplacement) => {
    const newList = replacementList.filter(x => x.id !== wordReplacement.id)

    swal.fire({
      title: 'Delete Word Replacement?',
      text: 'Note that this will also remove unsaved changes. Are you sure you want to proceed? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        if (Object.values(wordReplacements).find(x => x.id === wordReplacement.id)){
          deleteWordReplacement(wordReplacement, deleteFn)
        }
        setState({ replacementList: newList })
      }
    })
  }

  const updateWord = (wordReplacementId, replaceWith) => {
    const newList = replacementList
    const toUpdate = newList.find(x => x.id === wordReplacementId)
    toUpdate.input = replaceWith.input
    toUpdate.output = replaceWith.output

    setState({ replacementList: newList })
  }

  const saveWordReplacements = () => {
    const changes = replacementList.filter(x => !Object.values(wordReplacements).some((y) => {
      const { sort, ...temp } = x
      return JSON.stringify(temp) === JSON.stringify(y)
    }))

    changes.map((x) => {
      const { id, ...word } = x
      if (Object.values(wordReplacements).find(y => y.id === x.id)){
        updateWordReplacement(x, updateFn)
      } else if (word.input.trim() === '' && word.output.trim() === ''){
        // ignore
      } else {
        createWordReplacement(word, createFn)
      }
    })
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header callbacks={callbacks} title="Word" titleSecondLine="Replacement" />
      <SidebarModal.Content>
        <Box flexDirection="column" border="1px solid" borderColor="lineColor" borderRadius={5}>
          {replacementList.sort((a, b) => (a.sort > b.sort ? 1 : -1)).map(wordReplacement => (
            <WordReplacement
              key={wordReplacement.id}
              callbacks={{
                toggleRemoveWordReplacement: () => removeWord(wordReplacement),
                toggleUpdateWordReplacement: value => updateWord(wordReplacement.id, value),
              }}
              replacementDetails={wordReplacement}
            />
          ))}
          <ListItem
            boxProps={{
              borderBottom: '1px solid',
              padding: 'large',
            }}
            flexDirection="column"
          >
            <Box marginLeft="auto" width="auto">
              <Button
                buttonStyle="secondaryCreate"
                icon={<FontAwesomeIcon icon={faPlus} />}
                loading={deleting}
                onClick={() => addWord()}
                size="medium"
                style={{ width: 'auto' }}
              >
                Add Word
              </Button>
            </Box>
          </ListItem>
        </Box>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          loading={creating || deleting || updating}
          onClick={() => saveWordReplacements()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

CreateOrEditWordReplacementsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

CreateOrEditWordReplacementsModal.defaultProps = {
  modalKey: 'CreateOrEditWordReplacementsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditWordReplacementsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
