import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'

import AssetScreen from '@screens/AssetScreen'
import ErrorPage from '@screens/ErrorPage'
import ServiceJob from '@screens/ServiceJob'

import BackOffice from '.'
import Dashboard from './packs/Dashboard'
import LiveCapacity from './packs/LiveCapacity'
import SystemManager from './packs/SystemManager'

import Overview from './packs/Dashboard/screens/Overview'
import Calendar from './packs/LiveCapacity/Calendar'
import ClientEdit from './packs/SystemManager/Clients/screens/ClientEdit'
import ClientList from './packs/SystemManager/Clients/screens/ClientList'
import UserEdit from './packs/SystemManager/Users/screens/UserEdit'
import UserList from './packs/SystemManager/Users/screens/UserList'
import YoutubeAuthorization from './packs/SystemManager/Youtube/screens/YoutubeAuthorization'
import YoutubeList from './packs/SystemManager/Youtube/screens/YoutubeList'
import ZoneEdit from './packs/SystemManager/Zones/screens/ZoneEdit'
import ZoneList from './packs/SystemManager/Zones/screens/ZoneList'

const BackOfficeRoutes = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Overview />} />
        <Route path="service-jobs/:id" element={<ServiceJob userType="BackOffice" />} />
        <Route path="service-jobs/:id/assets" element={<AssetScreen />} />
      </Route>
      <Route path="/systemManager" element={<SystemManager />}>
        <Route path="clients" element={<ClientList />} />
        <Route path="clients/:clientId/edit" element={<ClientEdit />} />
        <Route path="clients/new" element={<ClientEdit isNew />} />
        <Route path="users" element={<UserList />} />
        <Route path="users/:userId/edit" element={<UserEdit />} />
        <Route path="users/new" element={<UserEdit isNew />} />
        <Route path="youtube" element={<YoutubeList />} />
        <Route path="youtube/authorize" element={<YoutubeAuthorization />} />
        <Route path="zones" element={<ZoneList />} />
        <Route path="zones/:zoneId/edit" element={<ZoneEdit />} />
        <Route path="zones/new" element={<ZoneEdit isNew />} />
      </Route>
      <Route path="/liveCapacity" element={<LiveCapacity />}>
        <Route index element={<Calendar />} />
      </Route>
    </Route>,
  ))

  return (
    <BackOffice>
      <RouterProvider router={router} />
    </BackOffice>
  )
}

export default BackOfficeRoutes
