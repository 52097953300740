import React from 'react'
import { Box, DashboardModule } from '@campaignhub/suit-theme'

import CategoryFilter from './components/CategoryFilter'
import WeekFilter from './components/WeekFilter'
import ZoneFilter from './components/ZoneFilter'

const Tools = () => (
  <DashboardModule title="Tools">
    <Box flexDirection="column" padding="large">
      <ZoneFilter />
      <CategoryFilter />
      <WeekFilter />
    </Box>
  </DashboardModule>
  )

export default Tools
