import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'
import serviceJobNoteTypes from '@functions/serviceJobNoteTypes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import ServiceJobNote from '@components/ServiceJobNote'
import QCScore from '@components/QCScore'
import Checklist from './components/Checklist'
import ProofingNotes from './components/ProofingNotes'

const defaultState = {
  showAssetDetails: true,
  showJobDetails: true,
}

const EditorTab = () => {
  const [state, setState] = useSetState(defaultState)
  const { showAssetDetails, showJobDetails } = state

  const { currentUser, serviceJob, serviceJobId, users, assignedUserRole } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <Box flexDirection="column" width="100%">
      <Box borderBottom="1px solid" borderColor="lineColor" flexDirection="column">
        <Box
          backgroundColor="backgroundColor"
          borderBottom="1px solid"
          borderColor="lineColor"
          flexDirection="row"
          justifyContent="space-between"
          onClick={() => setState({ showJobDetails: !showJobDetails })}
          padding="large"
          style={{ cursor: 'pointer' }}
        >
          <Text fontSize="small" fontWeight="500">JOB DETAILS</Text>
          <Box color="bodyFontLightColor" width="auto">
            <FontAwesomeIcon icon={showJobDetails ? faAngleUp : faAngleDown} />
          </Box>
        </Box>
        {showJobDetails ? (
          <Box flexDirection="column" paddingX="large">
            <ListItem
              boxProps={{
              borderBottom: '1px dashed',
              paddingY: 'large',
              paddingBottom: serviceJob.postProductionChecklistItems?.length > 0 ? 0 : 'large',
            }}
              flexDirection="column"
              disableHover
              style={{ cursor: 'pointer' }}
            >
              <Checklist postProductionChecklist={serviceJob.postProductionChecklistItems || []} />
            </ListItem>
            {(['ProductionManager'].includes(users[currentUser.id]?.userRole.name)
            || assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'QualityControl')) && (
              <ListItem
                boxProps={{
                  borderBottom: '1px dashed',
                  paddingY: 'large',
                }}
                flexDirection="column"
                disableHover
                style={{ cursor: 'pointer' }}
              >
                <QCScore serviceJob={serviceJob} />
              </ListItem>
            )}
            <ListItem
              boxProps={{
                borderBottom: '1px dashed',
                paddingY: 'large',
              }}
              flexDirection="column"
              disableHover
              style={{ cursor: 'pointer' }}
            >
              <ServiceJobNote
                editable
                title={serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting' ? "COPYWRITER'S NOTE" : "EDITOR'S NOTE"}
                serviceJobId={serviceJobId}
                serviceJobNoteTypeId={serviceJobNoteTypes.studio.id}
              />
            </ListItem>
          </Box>
        ) : null}
      </Box>
      <Box flexDirection="column">
        <Box
          backgroundColor="backgroundColor"
          borderBottom="1px solid"
          borderColor="lineColor"
          flexDirection="row"
          justifyContent="space-between"
          onClick={() => setState({ showAssetDetails: !showAssetDetails })}
          padding="large"
          style={{ cursor: 'pointer' }}
        >
          <Text fontSize="small" fontWeight="500">ASSET DETAILS</Text>
          <Box color="bodyFontLightColor" width="auto">
            <FontAwesomeIcon icon={showAssetDetails ? faAngleUp : faAngleDown} />
          </Box>
        </Box>
        {showAssetDetails ? (
          <Box flexDirection="column" paddingX="large">
            <ProofingNotes />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

EditorTab.propTypes = {}

export default EditorTab
