import React, { useContext } from 'react'

import PageContext from '@contexts/pageContext'
import { Grid, Text } from '@campaignhub/suit-theme'

const Header = React.memo(() => {
  const { calendarDates } = useContext(PageContext)

  return (
    <Grid
      backgroundColor="white"
      gridArea="header"
      position="sticky"
      top="0"
      zIndex="5"
    >
      <Grid
        gridColumnGap="large"
        gridTemplateColumns="repeat(5, minmax(160px, 1fr))"
      >
        {calendarDates.map(date => (
          <Grid textAlign="center">
            <Text
              fontSize="xsmall"
              padding="large"
              style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
            >
              {date.toFormat('ccc LLL dd, y').toUpperCase()}
            </Text>
            <Grid gridTemplateColumns="repeat(2, 1fr)">
              <Grid
                justifyContent="center"
                padding="medium"
                style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                textAlign="center"
              >
                <Text fontSize="xsmall" color="bodyFontLightColor">Total Capacity</Text>
              </Grid>
              <Grid
                justifyContent="center"
                padding="medium"
                style={{ boxSizing: 'border-box', boxShadow: '0 0 0 .5px #ECECEC' }}
                textAlign="center"
              >
                <Text fontSize="xsmall" color="bodyFontLightColor">Remaining Capacity</Text>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
})

export default Header
