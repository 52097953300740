import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompress, faExpand, faEye } from '@fortawesome/pro-light-svg-icons'

import PageContext from '@contexts/pageContext'
import { Button, PageHeader } from '@campaignhub/suit-theme'

const Header = () => {
  const {
    callbacks: { toggleFullScreen, toggleShowAllComments },
    fullscreen,
    isMobileDevice,
    serviceJob,
    showAllComments,
    showMarkers,
  } = useContext(PageContext)

  const navigate = useNavigate()

  return (
    <PageHeader
      boxProps={{ justifyContent: 'flex-start' }}
      offset={{ left: 0, top: 0 }}
      width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
      title="Asset Page"
      actionContent={(
        <>
          {showMarkers && (
            <Button
              buttonStyle={showAllComments ? 'primaryUtility' : 'ghostUtility'}
              icon={<FontAwesomeIcon icon={faEye} />}
              marginLeft="medium"
              onClick={toggleShowAllComments}
              size="medium"
              width="auto"
            >
              {showAllComments ? 'Unresolved Only' : 'All Comments'}
            </Button>
          )}
          <Button
            buttonStyle={fullscreen ? 'primaryEdit' : 'ghostEdit'}
            icon={<FontAwesomeIcon icon={fullscreen ? faCompress : faExpand} />}
            marginRight="medium"
            marginLeft="medium"
            onClick={() => toggleFullScreen()}
            size="medium"
            width="auto"
          >
            {!isMobileDevice && (fullscreen ? 'Compress' : 'Expand')}
          </Button>
          <Button buttonStyle="primaryCreate" size="medium" width="auto" onClick={() => navigate(`/service-jobs/${serviceJob.id}`)}>
            Go Back
          </Button>
        </>
      )}
    />
  )
}

export default Header
