import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Image, Text, Link, ListItem,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import PageContext from '@contexts/pageContext'

const defaultState = {
  versions: [],
}

const VersionList = (props) => {
  const { assetSetState, contents } = props

  const [state, setState] = useSetState(defaultState)
  const { versions } = state

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const selectedImage = (assetUrl, previewUrl, version) => {
    contents.assetUrl = assetUrl
    contents.previewUrl = previewUrl
    contents.version = version
    assetSetState({ selectedAsset: contents })
  }

  useEffect(() => {
    if (contents?.histories.length){
      setState({ versions: [...new Map(contents.histories.map(item => [item.version, item])).values()] })
    }
  }, [contents])

  const latestVersion = contents?.histories.reduce((x, y) => ((x.version > y.version) ? x : y))

  return (
    <>
      {versions.map(content => (
        <ListItem
          boxProps={{ paddingBottom: 'large' }}
          flexDirection="column"
          disableHover
          key={content.id}
        >
          <Box
            key={content.id}
            onClick={() => selectedImage(content.assetUrl, content.previewUrl, content.version)}
          >
            <Image
              boxProps={{
                border: contents.version === content.version ? '2px solid' : '1px solid',
                borderColor: contents.version === content.version ? 'blue' : '#ececec',
                borderRadius: 5,
                backgroundSize: 'contain',
              }}
              url={content.previewGeneratedAt ? content.previewUrl : ''}
              width="100%"
              height={182}
            >
              {!content.previewGeneratedAt && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                </Box>
              )}
            </Image>
          </Box>
          <Box flexDirection="row" paddingY="medium" alignItems="center" justifyContent="center">
            <Text color="bodyFontLightColor" fontSize="small" marginRight="small">
              Version {content.version} {(latestVersion?.version === content.version) ? '(Current)' : ''}
            </Text>
            <Link href={content.assetUrl}>
              <FontAwesomeIcon icon={faDownload} size="sm" />
            </Link>
          </Box>
        </ListItem>
        )).reverse()}
    </>
  )
}

VersionList.propTypes = {
  assetSetState: PropTypes.func,
  contents: PropTypes.object,
}

export default VersionList
