import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'

import { Box, Button } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

const ActionButtons = (props) => {
  const { boxProps } = props

  const {
    callbacks: { toggleShowMarkers },
    selectedAsset,
    showMarkers,
  } = useContext(PageContext)

  return (
    <Box flexDirection="row" {...boxProps}>
      <Button
        as="a"
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faDownload} />}
        href={selectedAsset.assetUrl}
        size="medium"
        style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
      >
        Download
      </Button>
      <Button
        buttonStyle={showMarkers ? 'primaryEdit' : 'ghostEdit'}
        icon={<FontAwesomeIcon icon={showMarkers ? faEyeSlash : faEye} />}
        onClick={toggleShowMarkers}
        size="medium"
        style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
      >
        {showMarkers ? 'Hide' : 'Show'} Markers
      </Button>
    </Box>
  )
}

ActionButtons.propTypes = {
  boxProps: PropTypes.object,
}

export default ActionButtons
