import useReduxAction from '@hooks/useReduxAction'
import useUserRoles from '@hooks/useUserRoles'
import useServiceGroups from '@hooks/useServiceGroups'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'

const OnSite = (props) => {
  const { children } = props

  useReduxAction('employmentTypes', 'loadEmploymentTypesV3', {})
  useReduxAction('resourceTypes', 'loadResourceTypes', {})
  useReduxAction('serviceTypes', 'loadServiceTypesV3', {})
  useReduxAction('wordReplacements', 'loadWordReplacements', {})

  useUserRoles({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })
  return children
}

export default OnSite
