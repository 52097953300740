const state = {
  id: null,
  name: '',
  reference: '',
  dusk_times: [],
}

export const requiredFields = [
  { key: 'name' },
]

export default state
