/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useReduxAction from '@hooks/useReduxAction'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

const defaultState = {
  clientState: [],
  showFilter: false,
}

const ClientFilter = (props) => {
  const { setJobState, jobState } = props
  const { clientFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { clientState, showFilter } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { clients } = entities

  const options = {
    includes: 'clientType',
  }

  useReduxAction('clients', 'loadClients', options)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const groupClientByClientType = (array, key, item) => Object.values(array).reduce((result, currentValue) => {
    (result[currentValue[key][item]] = result[currentValue[key][item]] || []).push({
      ...currentValue,
      isChecked: clientFilter.includes(currentValue.id),
    })
    return result
  }, {})

  useEffect(() => {
    setState({ clientState: groupClientByClientType(clients, 'clientType', 'name') })
  }, [clients, clientFilter])

  const updateClientState = (checked, clientId) => {
    if (checked){ setJobState({ clientFilter: [...clientFilter, clientId] }) } else {
      setJobState({ clientFilter: clientFilter.filter(i => i !== clientId) })
    }
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? Object.values(clients) : clientState[type]
    let checklist = []

    if (checked){
      filterList.map((user) => {
        checklist = [...checklist, ...clientFilter, user.id]
      })
    } else {
      checklist = [...clientFilter]
      filterList.map((user) => {
        checklist = checklist.filter(i => i === user.id)
      })
    }

    setJobState({ clientFilter: [...new Set(checklist)] })
  }

  if (Object.keys(clientState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          defaultValue="Clients"
        >
          <option>Clients</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, 'All')} />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(clientState).map(type => (
                <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                  <input type="checkbox" className={custom.checkbox} onChange={e => selectAll(e.target.checked, type)} />
                  <Text fontSize="small" marginLeft="medium">Select All {type}</Text>
                </Box>
              ))}
              {Object.keys(clientState).map(clientGroup => (
                <>
                  <Text fontSize="small" fontWeight="medium" paddingBottom="medium" paddingTop="large">{clientGroup}</Text>
                  {clientState[clientGroup].map(client => (
                    <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center">
                      <input
                        className={custom.checkbox}
                        onChange={e => updateClientState(e.target.checked, client.id)}
                        type="checkbox"
                        checked={client.isChecked}
                      />
                      <Text fontSize="small" marginLeft="medium">{client.name}</Text>
                    </Box>
                  ))}
                </>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

ClientFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ClientFilter
