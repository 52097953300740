import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
const asset = new schema.Entity('assets')
const assetComment = new schema.Entity('assetComments')
const assetCommentType = new schema.Entity('assetCommentTypes')
const blockedServiceJob = new schema.Entity('blockedServiceJobs')
const blockout = new schema.Entity('blockouts')
const capacity = new schema.Entity('capacities')
const client = new schema.Entity('clients')
const clientV3 = new schema.Entity('clientsV3')
const clientYoutubeChannel = new schema.Entity('clientYoutubeChannels')
const emailReplacement = new schema.Entity('emailReplacements')
const filter = new schema.Entity('filters')
const image = new schema.Entity('images')
const notificationType = new schema.Entity('notificationTypes')
const resource = new schema.Entity('resources')
const resourceType = new schema.Entity('resourceTypes')
const resourcesCompressRequest = new schema.Entity('resourcesCompressRequests')
const service = new schema.Entity('services')
const serviceCategory = new schema.Entity('serviceCategories')
const serviceGroup = new schema.Entity('serviceGroups')
const serviceJob = new schema.Entity('serviceJobs')
const serviceJobDetail = new schema.Entity('serviceJobDetails')
const serviceJobNote = new schema.Entity('serviceJobNotes')
const serviceJobStatus = new schema.Entity('serviceJobStatuses')
const serviceJobUser = new schema.Entity('serviceJobUsers')
const serviceJobUserRole = new schema.Entity('serviceJobUserRoles')
const statistic = new schema.Entity('statistics')
const user = new schema.Entity('users')
const userRole = new schema.Entity('userRoles')
const userV3 = new schema.Entity('usersV3')
const wordReplacement = new schema.Entity('wordReplacements')
const workflow = new schema.Entity('workflows')
const youtubeChannel = new schema.Entity('youtubeChannels')
const zone = new schema.Entity('zones')
const zoneV3 = new schema.Entity('zonesV3')

asset.define({
  comments: [assetComment],
})

serviceCategory.define({
  services: [service],
})

serviceGroup.define({
  services: [service],
})

serviceJob.define({
  notes: [serviceJobNote],
  details: [serviceJobDetail],
})

// Export Schemas
export const Schemas = {
  ASSET: asset,
  ASSET_ARRAY: [asset],
  ASSET_COMMENT: assetComment,
  ASSET_COMMENT_ARRAY: [assetComment],
  ASSET_COMMENT_TYPE: assetCommentType,
  ASSET_COMMENT_TYPE_ARRAY: [assetCommentType],
  BLOCKED_SERVICE_JOB_TYPE: blockedServiceJob,
  BLOCKED_SERVICE_JOB_TYPE_ARRAY: [blockedServiceJob],
  BLOCKOUT: blockout,
  BLOCKOUT_ARRAY: [blockout],
  CAPACITY: capacity,
  CAPACITY_ARRAY: [capacity],
  CLIENT: client,
  CLIENTV3: clientV3,
  CLIENTV3_ARRAY: [clientV3],
  CLIENT_ARRAY: [client],
  CLIENT_YOUTUBE_CHANNEL: clientYoutubeChannel,
  CLIENT_YOUTUBE_CHANNEL_ARRAY: [clientYoutubeChannel],
  EMAIL_REPLACEMENT: emailReplacement,
  EMAIL_REPLACEMENT_ARRAY: [emailReplacement],
  FILTER: filter,
  FILTER_ARRAY: [filter],
  IMAGE: image,
  IMAGE_ARRAY: [image],
  NOTIFICATION_TYPE: notificationType,
  NOTIFICATION_TYPE_ARRAY: [notificationType],
  RESOURCE: resource,
  RESOURCE_ARRAY: [resource],
  RESOURCE_TYPE: resourceType,
  RESOURCE_TYPE_ARRAY: [resourceType],
  RESOURCES_COMPRESS_REQUEST: resourcesCompressRequest,
  RESOURCES_COMPRESS_REQUEST_ARRAY: [resourcesCompressRequest],
  SERVICE: service,
  SERVICE_ARRAY: [service],
  SERVICE_CATEGORY: serviceCategory,
  SERVICE_CATEGORY_ARRAY: [serviceCategory],
  SERVICE_GROUP: serviceGroup,
  SERVICE_GROUP_ARRAY: [serviceGroup],
  SERVICE_JOB: serviceJob,
  SERVICE_JOB_ARRAY: [serviceJob],
  SERVICE_JOB_DETAIL: serviceJobDetail,
  SERVICE_JOB_DETAIL_ARRAY: [serviceJobDetail],
  SERVICE_JOB_NOTE: serviceJobNote,
  SERVICE_JOB_NOTE_ARRAY: [serviceJobNote],
  SERVICE_JOB_STATUS: serviceJobStatus,
  SERVICE_JOB_STATUS_ARRAY: [serviceJobStatus],
  SERVICE_JOB_USER: serviceJobUser,
  SERVICE_JOB_USER_ARRAY: [serviceJobUser],
  SERVICE_JOB_USER_ROLE: serviceJobUserRole,
  SERVICE_JOB_USER_ROLE_ARRAY: [serviceJobUserRole],
  STATISTIC: statistic,
  STATISTIC_ARRAY: [statistic],
  USER: user,
  USERV3: userV3,
  USERV3_ARRAY: [userV3],
  USER_ARRAY: [user],
  USER_ROLE: userRole,
  USER_ROLE_ARRAY: [userRole],
  WORD_REPLACEMENT: wordReplacement,
  WORD_REPLACEMENT_ARRAY: [wordReplacement],
  WORKFLOW: workflow,
  YOUTUBE_CHANNEL: youtubeChannel,
  YOUTUBE_CHANNEL_ARRAY: [youtubeChannel],
  ZONE: zone,
  ZONE_ARRAY: [zone],
  ZONEV3: zoneV3,
  ZONEV3_ARRAY: [zoneV3],
}

export default Schemas
