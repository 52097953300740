import React, { useContext, useEffect } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSetState, useDebounce } from '@campaignhub/react-hooks'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, DashboardModule, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import '@styles/swal-custom.css'

import useCurrentUser from '@hooks/useCurrentUser'
import useBlockout, { useBlockoutForm } from '@hooks/useBlockout'
import useUsersV3 from '@hooks/useUsersV3'
import useReduxAction from '@hooks/useReduxAction'

import LinkingTags from '@components/LinkingTags'

const createOrUpdateBlockout = (blockout, props, createFn, updateFn, users) => {
  const updatedBlockout = blockout
  updatedBlockout.reason = updatedBlockout.reason.trim() === '' ? '-' : updatedBlockout.reason
  const {
    callbacks: { createBlockout, updateBlockout }, userId,
  } = props

  users.filter(x => x.id !== userId).forEach((user) => {
    updatedBlockout.user = `User/${user.id}`
    createBlockout(updatedBlockout, createFn)
  })

  updatedBlockout.user = `User/${userId}`
  if (blockout.id){
    return updateBlockout(updatedBlockout, updateFn)
  }
  return createBlockout(updatedBlockout, createFn)
}

const mapResults = (providers, linkedUsers) => {
  const results = Object.values(providers).filter(provider => !linkedUsers.find(linked => linked.id === provider.id))

  return {
    groups: [
      {
        key: 'providers',
        title: 'Providers',
        items: results.map((result) => {
          const { name, reference } = result

          return {
            ...result,
            key: reference,
            text: name,
          }
        }),
      },
    ],
  }
}

const defaultState = {
  finishDate: '',
  finishTime: '',
  saveEnabled: false,
  startDate: '',
  startTime: '',
  userSerch: '',
  users: [],
}

const CreateOrEditBlockoutModal = (props) => {
  const {
    callbacks, modalKey, showModal,
  } = props

  const { closeModal, deleteBlockout } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})
  const {
    blockout,
  } = modalPayload

  const blockoutFormPayload = useBlockoutForm(blockout)
  const {
    entityState,
    entityState: {
      id,
      start,
      finish,
      reason,
    },
    errors,
    handlers,
  } = blockoutFormPayload

  const {
    callbacks: {
      createBlockout: createFn,
      deleteBlockout: deleteFn,
      updateBlockout: updateFn,
    },
    creating,
    deleting,
    updating,
  } = useBlockout(blockout)

  const { currentUser } = useCurrentUser()

  // ToDo: CH-1285 - this is a temporary condition, maybe we need another role that has higher access than BackOffice like "Admin"
  const canBulkApply = ['glennv@campaigntrack.com', 'pearls@campaigntrack.com', 'monica.ramos@contenthouse.io'].includes(currentUser.email)

  const startUTC = DateTime.fromISO(start, { zone: 'utc' })
  const finishUTC = DateTime.fromISO(finish, { zone: 'utc' })

  const [state, setState] = useSetState(defaultState)
  const { finishDate, finishTime, startDate, startTime, saveEnabled, userSerch, users } = state

  const debouncedSearchFilter = useDebounce(userSerch, 300)

  useEffect(() => {
    setState({ searchFilter: debouncedSearchFilter })
  }, [debouncedSearchFilter])

  const searchFilterString = `"name":"${userSerch.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const roleString = '"roles*": "Provider"'
  const urlComponent = (`{${userSerch.length > 0 ? `${searchFilterString},` : ''}
                          ${`${roleString},`}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: canBulkApply ? -1 : 1, // Just because I don't want to create a "Refresh" compo to make this conditional quickly
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadUsersV3', options, [userSerch, debouncedSearchFilter])

  const { filteredUsersV3, loading } = useUsersV3({
    searchFilter: userSerch,
    selectedRole: ['Provider'],
  })

  useEffect(() => {
    setState({
      finishDate: finishUTC.startOf('day').toFormat('y-MM-dd'),
      finishTime: finishUTC.toFormat('HH:mm'),
      startDate: startUTC.startOf('day').toFormat('y-MM-dd'),
      startTime: startUTC.toFormat('HH:mm'),
    })
  }, [id])

  useEffect(() => {
    entityState.finish = DateTime.fromISO(`${finishDate}T${finishTime}`, { zone: 'utc' }).toISO()
    entityState.start = DateTime.fromISO(`${startDate}T${startTime}`, { zone: 'utc' }).toISO()
  }, [finishDate, finishTime, startDate, startTime])

  useEffect(() => {
    if (entityState.start !== null && entityState.finish !== null) setState({ saveEnabled: true })
  }, [finishDate, finishTime, startDate, startTime])

  function deleteBlocked(){
    swal.fire({
      title: 'Delete Blockout',
      html: 'This action will delete this blockout.'
      + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
    }).then(({ value }) => {
      if (value){
        deleteBlockout(deleteFn)
      }
    })
  }

  function createOrUpdateBlockoutConfirm(){
    swal.fire({
      title: 'Blockout Reminder',
      html: 'Blockouts do not cancel or nullify existing appointments.'
      + '<br /> If a job has already been assigned to you prior to adding the blockout, it will remain on your job list.'
      + '<br /> If you need it removed, please contact the Operations Team.',
      icon: 'warning',
      confirmButtonText: 'Okay',
      confirmButtonColor: '#e2001a',
      customClass: {
        content: 'swal-content-blockout',
      },
    }).then(({ value }) => {
      if (value){
        createOrUpdateBlockout(entityState, props, createFn, updateFn, users)
      }
    })
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="large">
      <SidebarModal.Header
        callbacks={callbacks}
        title={id ? 'Edit' : 'Add'}
        titleSecondLine="Blockout"
      />
      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="Start Date &#38; Time" errorMessage={errors.start}>
            <Box flexDirection="row">
              <input
                max={finishDate}
                onChange={e => setState({ startDate: e.target.value })}
                type="date"
                value={startDate}
              />
              <input
                max={finishTime}
                onChange={e => setState({ startTime: startDate === finishDate && e.target.value > finishTime ? finishTime : e.target.value })}
                type="time"
                value={startTime}
              />
            </Box>
          </FormField>
        </Box>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="End Date &#38; Time" errorMessage={errors.finish}>
            <Box flexDirection="row">
              <input
                min={startDate}
                onChange={e => setState({ finishDate: e.target.value })}
                type="date"
                value={finishDate}
              />
              <input
                min={startTime}
                onChange={e => setState({ finishTime: startDate === finishDate && e.target.value < startTime ? startTime : e.target.value })}
                type="time"
                value={finishTime}
              />
            </Box>
          </FormField>
        </Box>
        <Box flexDirection="column" marginBottom="large">
          <FormField label="Reason">
            <textarea
              name="reason"
              type="text"
              style={{ height: 90, resize: 'vertical' }}
              value={reason}
              maxLength="300"
              {...handlers}
            />
          </FormField>
        </Box>
        {id && (
          <Box flexDirection="column" marginBottom="large">
            <FormField direction="column" label="More Options">
              <Button
                buttonStyle="secondaryUtility"
                loading={deleting}
                onClick={() => deleteBlocked()}
                size="medium"
                width="100%"
              >
                Delete Blockout
              </Button>
            </FormField>
          </Box>
        )}
        {canBulkApply && (
          <DashboardModule
            customHeader={(
              <Box borderBottom="1px solid" borderColor="lineColor" padding="large" width="auto" alignItems="center">
                <Text color="bodyFontLightColor" letterSpacing="0.5px" fontSize="small" width="auto">
                  Apply to Other Providers
                </Text>
                <Box alignItems="center" justifyContent="flex-end" marginLeft="auto" width="auto">
                  <Button
                    buttonStyle="secondaryCreate"
                    onClick={() => setState({ users: filteredUsersV3 })}
                    size="medium"
                  >
                    Apply to All
                  </Button>
                </Box>
              </Box>
            )}
            marginTop="large"
          >
            <Box flexDirection="column" padding="large">
              <LinkingTags
                callbacks={{
                  toggleItem: item => setState({ users: [...users, item] }),
                  toggleSearch: search => setState({ userSerch: search }),
                  removeItem: item => setState({ users: users.filter(x => x.id !== item.id) }),
                }}
                label="Providers"
                linkedItems={users}
                loading={loading}
                secondLabel={`(${users.length})`}
                searchFilter={userSerch}
                searchResults={mapResults(filteredUsersV3, users)}
              />
            </Box>
          </DashboardModule>
        )}
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          onClick={() => createOrUpdateBlockoutConfirm()}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>

  )
}

CreateOrEditBlockoutModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  userId: PropTypes.string,
}

CreateOrEditBlockoutModal.defaultProps = {
  modalKey: 'CreateOrEditBlockoutModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditBlockoutModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
