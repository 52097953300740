import React, { useContext } from 'react'
import ImageMarker from 'react-image-marker'

import { Box, Grid, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import useUsers from '@hooks/useUsers'

import CustomMarker from '../../../AssetScreen/components/AssetMarker/components/CustomMarker'
import styles from '../../../AssetScreen/components/AssetMarker/styles.module.scss'

const AssetSection = () => {
  const {
    assignedUserRole,
    currentUser,
    markerSetState,
    markers,
    selectedAsset,
    showAllComments,
    showMarkers,
  } = useContext(PageContext)

  const { users } = useUsers()

  return (
    <Box flexDirection="column" lineHeight="1.5" position="relative">
      <Grid
        gridTemplateColumns="1fr"
        gridAutoRows="min-content"
        alignContent="center"
        alignItems="center"
        gridGap="medium"
        justifyContent="center"
        width="100%"
      >
        {selectedAsset?.file?.previewGeneratedAt ? (
          <ImageMarker
            markers={showMarkers ? (showAllComments ? markers : markers.filter(x => x.status === null || !x.status)) : []}
            markerComponent={markerProps => CustomMarker({
              markerProps, markers, markerSetState,
            })}
            onAddMarker={(marker) => {
              if (['ProductionManager'].includes(users[currentUser?.id]?.userRole.name) || assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'QualityControl')){
                markerSetState({ markers: [...markers, marker] })
              }
            }}
            src={selectedAsset?.previewUrl}
            extraClass={styles.fullMarkerImg}
          />
        ) : (
          <Grid alignItems="center" justifyContent="center" height="90vh">
            <Text fontSize="small" color="bodyFontLightColor">No preview generated</Text>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default AssetSection
