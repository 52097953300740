/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import swal from 'sweetalert2'

import { Box, Checkbox, Text, Tag } from '@campaignhub/suit-theme'

import { faSlidersH, faTrash, faFolderDownload } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useReduxAction from '@hooks/useReduxAction'
import useServiceJob from '@hooks/useServiceJob'
import useUploadResource from '@hooks/useUploadResource'

import custom from '@styles/custom.module.scss'
import Footer from './components/Footer'
import ListView from './components/ListView'

const defaultState = {
  resourceState: {},
  resourceTypeFilter: [],
  selectAll: false,
  showFilter: false,
  filteredResources: [],
  compressing: false,
  notificationsState: [],
}

const deleteSelectedResources = (resources, selectedIds, deleteFn) => {
  selectedIds.forEach((id) => {
    const resource = Object.values(resources).find(x => x.id === id)
    if (resource){
      deleteFn(resource).then(({ success, errors }) => {
        if (!success) toast.warning(errors[0])
      })
    }
  })
}

const zipSelectedResources = (selectedIds, createFn) => {
  createFn({ resources: selectedIds }).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const Resources = (props) => {
  const { callbacks: { toggleUpdateSelectedIds, toggleCheckActionStatus }, editable, selectedIds, serviceJob, activeTabBar } = props

  const [state, setState] = useSetState(defaultState)
  const { resourceState, resourceTypeFilter, selectAll, showFilter, filteredResources, notificationsState, compressing } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const entityReducer = useSelector(reduxState => reduxState.notifications)
  const { resources, resourceTypes } = entities
  const { result: notifications } = entityReducer

  const { filteredServiceResourceTypes } = useServiceJob(serviceJob)
  const useUploadResourcePayload = useUploadResource()
  const {
    callbacks: {
      deleteResource: deleteFn,
      createResourcesCompressRequests: createFn,
    },
  } = useUploadResourcePayload

  const groupResourcesByResourceType = (array, key, item) => array.reduce((result, currentValue) => {
    (result[currentValue[key][item]] = result[currentValue[key][item]] || []).push({
      ...currentValue,
    })
    return result
  }, {})

  useEffect(() => {
    if (activeTabBar === 'resources'){
      setState({ filteredResources: Object.values(resources).filter(resource => parseInt(resource.serviceJobId, 10) === serviceJob.id) })
    } else {
      setState({ filteredResources: Object.values(resources).filter(resource => parseInt(resource.serviceJobId, 10) !== serviceJob.id
        && resource.campaignId === serviceJob.campaign.id) })
    }
  }, [activeTabBar, serviceJob])

  useEffect(() => {
    const contents = filteredResources.filter(resource => resourceTypeFilter.includes(resource.resourceTypeId) || !resourceTypeFilter.length)

    const groupByRelatedResourceTypeIds = filteredResources?.reduce((filtered, resource) => {
      if (!filtered.find(x => x === resource.resourceTypeId)){
        filtered.push(resource.resourceTypeId)
      }
      return filtered
    }, [])

    setState({ resourceState: groupResourcesByResourceType(contents, 'resourceType', 'description') })
      groupByRelatedResourceTypeIds.forEach(i => (filteredServiceResourceTypes.find(x => x.id === i) ? filteredServiceResourceTypes
      : filteredServiceResourceTypes.push(...Object.values(resourceTypes)?.filter(y => y.id === i))))
  }, [resourceTypeFilter, serviceJob, filteredResources])

  useEffect(() => {
    toggleUpdateSelectedIds(selectAll ? (
      filteredResources.filter(resource => resourceTypeFilter.includes(resource.resourceTypeId) || !resourceTypeFilter.length).map(content => content.id)
    ) : [])
  }, [selectAll])

  useEffect(() => {
    const newSelectedIds = []
    selectedIds.forEach((id) => {
      const resource = Object.values(resources).find(r => r.id === id)
      if (resource){
        newSelectedIds.push(id)
      }
    })
    if (newSelectedIds.length !== selectedIds.length){
      if (selectAll){
        setState({ selectAll: false })
      } else {
        toggleUpdateSelectedIds(newSelectedIds)
      }
    }
  }, [resources])

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useReduxAction('resourcesCompressRequests', 'loadResourcesCompressRequests',
  { serviceJobId: serviceJob.id }, [notifications], {
    dispatchAction: (action, requestOptions) => action(requestOptions),
    shouldPerformFn: () => notificationsState.length > 0 && notifications.length > notificationsState.length,
  })

  useEffect(() => {
    // TODO: based on how notifs are fired. we might need to get the length of file zip notifs only
    if (notificationsState.length > 0 && notifications.length > notificationsState.length){
      setState({ notificationsState: [], compressing: false })
      toggleCheckActionStatus('zip-resources')
    }
  }, [notifications, notificationsState])

  const updateFilterState = (checked, resourceTypeId) => {
    const allResourceTypes = []
    Object.values(filteredServiceResourceTypes).map(x => allResourceTypes.push(x.id))

    if (checked){
      return setState({ resourceTypeFilter: resourceTypeId === 'All' ? allResourceTypes : [...resourceTypeFilter, resourceTypeId] })
    }

    return setState({ resourceTypeFilter: resourceTypeId === 'All' ? [] : resourceTypeFilter.filter(i => i !== resourceTypeId) })
  }

  const deleteResources = () => {
    swal.fire({
      title: 'Delete Selected Resources',
      html: `This action will delete the ${selectedIds.length} resources you have selected`
        + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        deleteSelectedResources(resources, selectedIds, deleteFn)
        toggleCheckActionStatus('delete-resources')
      }
    })
  }

  const zipResources = () => {
    swal.fire({
      title: 'Zip Selected Resources',
      html: `This action will zip the ${selectedIds.length} resources you have selected.`
        + '<br/>Do you wish to proceed?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        zipSelectedResources(selectedIds, createFn)
        setState({ notificationsState: notifications, compressing: true })
      }
    })
  }

  return (
    <>
      {filteredResources.length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="lineColor"
            flexDirection="row"
            padding="large"
            className={custom.root}
            ref={contentEl}
            style={{ justifyContent: 'space-between' }}
          >
            <Box width="auto" flexDirection="row" color="blue" onClick={() => setState({ showFilter: !showFilter })} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faSlidersH} />
              <Text paddingX="medium" color="blue">Filter</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
            {editable && (
            <Box
              alignItems="center"
              flexShrink="0"
              width="auto"
              style={{ marginRight: 6 }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                color={selectedIds.length > 0 ? 'red' : '#D3D3D3'}
                onClick={() => deleteResources()}
                title="Delete Resources"
                style={{ cursor: 'pointer', marginRight: 15, pointerEvents: selectedIds.length < 1 || compressing ? 'none' : 'auto' }}
              />
              <FontAwesomeIcon
                icon={faFolderDownload}
                color={(selectedIds.length > 0 && !compressing) ? 'green' : compressing ? '#FFBF00' : '#D3D3D3'}
                onClick={() => (!compressing ? zipResources() : null)}
                title={compressing ? 'Zipping...' : 'Generate Zip'}
                style={{ cursor: compressing ? 'auto' : 'pointer', height: 17, width: 17, marginRight: 15, pointerEvents: selectedIds.length < 1 ? 'none' : 'auto' }}
              />
              <Text color="bodyFontLightColor" fontSize="small" marginRight="small">Selected: </Text>
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', marginRight: 'large' }}>{selectedIds.length}</Tag>
              <Checkbox checked={selectAll} onClick={() => setState({ selectAll: !selectAll })} />
            </Box>
            )}
            {showFilter && (
              <Box className={custom.popup} width="300px">
                <Box flexDirection="column" maxHeight="280px" overflowY="auto">
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <input type="checkbox" className={custom.checkbox} onChange={e => updateFilterState(e.target.checked, 'All')} />
                    <Text fontSize="small" marginLeft="medium">Select All</Text>
                  </Box>
                  {Object.values(filteredServiceResourceTypes).map(type => (
                    <Box flexDirection="row" key={type.id} paddingBottom="medium" alignItems="center">
                      <input
                        type="checkbox"
                        className={custom.checkbox}
                        onChange={e => updateFilterState(e.target.checked, type.id)}
                        checked={resourceTypeFilter.find(x => x === type.id)}
                      />
                      <Text fontSize="small" marginLeft="medium">{type.description}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            {Object.keys(resourceState).map(resouceType => (
              <Box flexDirection="column" key={resouceType}>
                <Text fontSize="small" fontWeight={600} marginBottom="large">
                  {resouceType}
                </Text>
                {resourceState[resouceType].map((content) => {
                  const selected = selectedIds.includes(content.id)
                  return (
                    <ListView
                      callbacks={{ toggleUpdateSelectedIds, toggleCheckActionStatus }}
                      content={content}
                      editable={editable}
                      key={content.id}
                      selected={selected}
                      selectedIds={selectedIds}
                    />
                  )
                })}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {!filteredResources.length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No resources uploaded yet.
            </Text>
          </Box>
        </Box>
      )}
      {editable && (
      <Footer
        compressing={compressing}
        isMissingResources={filteredResources.filter(item => item.resourceType.id === 26).length < serviceJob.totalAssets}
        callbacks={{ toggleCheckActionStatus: status => toggleCheckActionStatus(status) }}
      />
)}
    </>
  )
}

Resources.propTypes = {
  callbacks: PropTypes.object,
  editable: PropTypes.bool,
  selectedIds: PropTypes.array,
  serviceJob: PropTypes.object,
  activeTabBar: PropTypes.string,
}

export default Resources
