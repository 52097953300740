import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import calculateContentOffset from '@components/utils/calculateContentOffset'

import {
  Box, Grid, Heading, Link, Text,
} from '@campaignhub/suit-theme'

import { faBath, faBed, faCar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const defaultState = {
  bed: '',
  bath: '',
  car: '',
  landArea: '',
  postCode: '',
  suburb: '',
  campaignState: '',
}

const JobHeader = (props) => {
  const { campaign, details, showViewMap } = props

  const [state, setState] = useSetState(defaultState)
  const { bed, bath, car, campaignState, landArea, postCode, suburb } = state

  useEffect(() => {
    setState({
      bed: '',
      bath: '',
      campaignState: '',
      car: '',
      landArea: '',
      postCode: '',
      suburb: '',
    })

    if (details?.length){
      setState({
        bed: details.find(x => x.entityFieldType.name === 'Bed')?.value || '',
        bath: details.find(x => x.entityFieldType.name === 'Bath')?.value || '',
        campaignState: details.find(x => x.entityFieldType.name === 'State')?.value || '',
        car: details.find(x => x.entityFieldType.name === 'Car')?.value || '',
        landArea: details.find(x => x.entityFieldType.name === 'LandArea')?.value || '',
        postCode: details.find(x => x.entityFieldType.name === 'PostCode')?.value || '',
        suburb: details.find(x => x.entityFieldType.name === 'Suburb')?.value || '',
      })
    }
  }, [details])

  const leftOffset = calculateContentOffset(0, {})

  return (
    <Box
      variant="white"
      borderBottom="1px solid"
      borderColor="lineColor"
      flexDirection="column"
      padding="large"
      position="fixed"
      px="large"
      lineHeight="1.5"
      style={{
        top: 50,
        right: 0,
        zIndex: 10,
        left: leftOffset,
        width: `calc(100% - ${leftOffset || 0}px)`,
      }}
      height={[112, 115]}
    >
      <Box flexDirection="column">
        <Heading weight="strong" size="large">{campaign?.name || '...'}</Heading>
        <Box flexDirection="row">
          <Box fontSize="small" flexShrink="0" flexDirection="column" width="auto" paddingRight="medium">
            <Text>{suburb ? `${JSON.parse(suburb).value}, ` : ''}{campaignState ? JSON.parse(campaignState).value : ''} {postCode ? JSON.parse(postCode).value : ''}</Text>
          </Box>
          {showViewMap && (
            <Box fontSize="xsmall" alignItems="center" marginLeft="auto">
              <Link>View Map</Link>
            </Box>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box fontSize="small" flexShrink="0" flexDirection="column" width="auto" paddingRight={landArea ? 'medium' : null}>
            <Text>{landArea ? `${JSON.parse(landArea).value} ${JSON.parse(landArea).unit}` : ''}{(landArea && (bath || bed || car)) ? ' |' : ''}</Text>
          </Box>
          <Grid
            gridGap="medium"
            gridAutoRows="1fr"
            gridTemplateColumns="repeat(auto-fill, minmax(10px, 40px))"
          >
            {bed && JSON.parse(bed).value > 0 && (
              <Box color="bodyFontLightColor" alignItems="center">
                <FontAwesomeIcon icon={faBed} />
                <Text fontSize="small" marginLeft="medium">{JSON.parse(bed).value}</Text>
              </Box>
            )}
            {bath && JSON.parse(bath).value > 0 && (
              <Box color="bodyFontLightColor" alignItems="center">
                <FontAwesomeIcon icon={faBath} />
                <Text fontSize="small" marginLeft="medium">{JSON.parse(bath).value}</Text>
              </Box>
            )}
            {car && JSON.parse(car).value > 0 && (
              <Box color="bodyFontLightColor" alignItems="center">
                <FontAwesomeIcon icon={faCar} />
                <Text fontSize="small" marginLeft="medium">{JSON.parse(car).value}</Text>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

JobHeader.propTypes = {
  campaign: PropTypes.object,
  details: PropTypes.array,
  showViewMap: PropTypes.bool,
}

export default JobHeader
