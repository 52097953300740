import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import { getEntityByName } from '@functions/getEntityByName'

import Gallery from '@components/Gallery'
import Footer from './components/Footer'

const defaultState = {
  contents: [],
  multipleSelection: false,
  selectAll: false,
}

const Assets = (props) => {
  const { activeTabBarItemKey, callbacks: { toggleUpdateSelectedIds }, selectedIds, serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const {
    contents, multipleSelection, selectAll,
  } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, serviceGroups, serviceJobStatuses } = entities

  const getContents = () => {
    const proofStatuses = [
      getEntityByName(serviceJobStatuses, 'Approved')?.id,
      getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id,
      getEntityByName(serviceJobStatuses, 'SentToClient')?.id,
    ]

    switch (activeTabBarItemKey){
      case 'assets':
        return Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id)
      case 'proofs':
        return Object.values(assets).filter(asset => (
          asset.serviceJobId === serviceJob.id && proofStatuses.includes(asset.serviceJobStatusId)
        ))
      default:
        return []
    }
  }

  useEffect(() => {
    setState({
      contents: getContents(),
      multipleSelection: false,
      selectAll: false,
    })
  }, [activeTabBarItemKey, assets])

  const selectMultiple = () => {
    setState({ multipleSelection: !multipleSelection })
  }

  const selectAllContent = () => {
    setState({ selectAll: !selectAll })
  }

  useEffect(() => {
    toggleUpdateSelectedIds(selectAll ? Object.values(assets).map(content => content.id) : [])
  }, [selectAll])

  useEffect(() => {
    setState({ selectAll: !multipleSelection ? false : selectAll })
    toggleUpdateSelectedIds(!multipleSelection ? [] : selectedIds)
  }, [multipleSelection])

  return (
    <>
      <Gallery
        callbacks={{
          toggleMultipleSelection: () => selectMultiple(),
          toggleSelectAllContent: () => selectAllContent(),
          toggleUpdateSelectedIds,
        }}
        contents={contents}
        consolidateContent={contents.find(x => x.copy?.id)}
        galleryPlaceholder={activeTabBarItemKey === 'proofs'
          ? 'No assets for proofing yet.'
          : `No assets ${['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) ? 'generated' : 'uploaded'} yet.`}
        maxSelection={['Floorplan'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) ? 1 : contents.length}
        multipleSelection={multipleSelection}
        selectAll={selectAll}
        selectedIds={selectedIds}
        showControls={activeTabBarItemKey === 'assets' && !['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)}
        showDetails
        type={activeTabBarItemKey}
      />
      <Footer
        contents={contents}
        selectedIds={selectedIds}
        showCreateAsset={!['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)}
        showGenerateAsset={['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) && contents.length === 0}
      />
    </>
  )
}

Assets.propTypes = {
  activeTabBarItemKey: PropTypes.string,
  callbacks: PropTypes.object,
  selectedIds: PropTypes.array,
  serviceJob: PropTypes.object,
}

export default Assets
