import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { ThemeProvider } from '@emotion/react'

import { theme } from '@campaignhub/suit-theme'

import '@campaignhub/suit-theme/dist/styles.css'
import '@styles/reset.css'

import AuthController from '@auth/AuthController'

import createRootReducer from '@redux/rootReducer'
import lastModifiedMiddleware from '@redux/middleware/lastModified'

import { getUserInfo, setUserInfo } from '@functions/staticWebAppUserInfo'

const getStaticWebAppUserInfo = async () => {
  const response = await fetch('/.auth/me')
  const payload = await response.json()
  const { clientPrincipal } = payload
  return clientPrincipal
}

function App(){
  const userInfo = getUserInfo()

  useEffect(() => {
    if (!userInfo){
      setUserInfo(getStaticWebAppUserInfo)
    }
  }, [getStaticWebAppUserInfo])

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActionPaths: ['_options.mergeOptions.customMerge'],
      },
    }).concat(lastModifiedMiddleware),
  })

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthController />
      </ThemeProvider>
    </Provider>
  )
}

export default App
