import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'

import useUsersV3 from '@hooks/useUsersV3'
import useReduxAction from '@hooks/useReduxAction'

import LinkingTags from '@components/LinkingTags'

const Load = (props) => {
  const { zoneId } = props

  const options = {
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadZoneProvidersV3', options, [zoneId], {
    dispatchAction: (action, requestOptions) => action(zoneId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors } = entityReducer
      return !errors.length
    },
  })

  return null
}

const defaultState = {
  refresh: false,
  reset: false,
  searchFilter: '',
}

const mapResults = (filteredProviders, linkedProviders) => {
  const results = Object.values(filteredProviders).filter(provider => !linkedProviders.find(linked => linked.id === provider.id))

  return {
    groups: [
      {
        key: 'providers',
        title: 'Providers',
        items: results.map((result) => {
          const { name, reference } = result

          return {
            ...result,
            key: reference,
            text: name,
          }
        }),
      },
    ],
  }
}

const ProviderLinking = (props) => {
  const { callbacks, linkedProviders, zoneForm } = props

  const [state, setState] = useSetState(defaultState)
  const { refresh, reset, searchFilter } = state

  const { toggleUpdateLinkedProviders } = callbacks

  const { result: { linkedProviders: updatedLinkedProviders } } = useSelector(reduxState => reduxState.usersV3)

  const {
    entityState: {
      id,
      reference,
    },
  } = zoneForm

  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  useEffect(() => {
    setState({ searchFilter: debouncedSearchFilter })
  }, [debouncedSearchFilter])

  const searchFilterString = `"name":"${searchFilter.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const roleString = '"roles*": "Provider"'
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}
                          ${`${roleString},`}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: 50,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadUsersV3', options, [searchFilter, debouncedSearchFilter])

  const { filteredUsersV3, loading } = useUsersV3({
    searchFilter,
    selectedRole: ['Provider'],
    reset,
    zoneReference: reference,
  })

  useEffect(() => {
    if (updatedLinkedProviders?.length && !linkedProviders.length){
      const newList = [...linkedProviders, ...updatedLinkedProviders]
      toggleUpdateLinkedProviders([...new Map(newList.map(item => [item.id, item])).values()])
    }
  }, [updatedLinkedProviders])

  useEffect(() => {
    toggleUpdateLinkedProviders(linkedProviders)
    setState({ refresh: true })
  }, [])

  const toggleProvider = (provider, type) => {
    let newList = []

    if (type === 'add'){
      newList = [...linkedProviders, provider]
    } else {
      newList = linkedProviders.filter(x => x.id !== provider.id)
    }

    toggleUpdateLinkedProviders(newList)
  }

  return (
    <>
      <LinkingTags
        callbacks={{
          toggleItem: item => toggleProvider(item, 'add'),
          toggleSearch: search => setState({ searchFilter: search }),
          removeItem: item => toggleProvider(item),
        }}
        itemsDirection="column"
        label="Providers"
        linkedItems={linkedProviders}
        loading={loading}
        secondLabel={`(${linkedProviders.length})`}
        searchFilter={searchFilter}
        searchResults={mapResults(filteredUsersV3, linkedProviders)}
      />
      {refresh && <Load zoneId={id} />}
    </>
  )
}

ProviderLinking.propTypes = {
  callbacks: PropTypes.object,
  linkedProviders: PropTypes.array,
  zoneForm: PropTypes.object,
}

export default ProviderLinking
