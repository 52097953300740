import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import Comment from '@suiteThemeCustom/Comment'
import PageContext from '@contexts/pageContext'

const AssetComment = (props) => {
  const { comment } = props
  const { currentUser, selectedAssetVersionCount } = useContext(PageContext)
  const commenter = JSON.parse(comment.details.find(x => x.entityFieldType?.name === 'Commenter').value).value
  const editOverlays = comment.details.filter(x => x.entityFieldType.name === 'EditOverlay')?.map(y => JSON.parse(y.value)?.value)
  const clientUploads = comment.details.filter(x => x.entityFieldType.name === 'ClientUpload')?.map(y => JSON.parse(y.value)?.value)

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'large',
        borderBottom: '1px dashed',
      }}
      flexDirection="row"
      disableHover
      key={comment.id}
    >
      <Box paddingBottom="xsmall" marginBottom="xsmall">
        <Box flexDirection="column" width="auto">
          <Text
            backgroundColor="mysteryGrey"
            borderRadius={20}
            color="white"
            fontSize="small"
            fontWeight={500}
            style={{ cursor: 'pointer' }}
            textAlign="center"
            lineHeight="34px"
            marginRight="large"
            width="34px"
          >
            {comment.commentIndex}
          </Text>
        </Box>
        <Comment
          boxProps={{ marginBottom: 0, borderBottom: 0, paddingBottom: 0 }}
          commentBody={comment.comment}
          commentImages={editOverlays}
          commentLinks={clientUploads}
          commentTime={DateTime.fromISO(comment.createdAt, { zone: currentUser.timeZone }).toFormat('dd LLLL y, hh:mm a')}
          fullName={`${commenter} ${selectedAssetVersionCount > 1 ? `(Version ${comment.version })` : ''}`}
          hideImage
        />
      </Box>
    </ListItem>
  )
}

AssetComment.propTypes = {
  comment: PropTypes.object,
}

export default AssetComment
