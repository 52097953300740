import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'

import useClientsV3 from '@hooks/useClientsV3'
import useReduxAction from '@hooks/useReduxAction'

import LinkingTags from '@components/LinkingTags'

const Load = (props) => {
  const { zoneId } = props

  const options = {
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('clientsV3', 'loadZoneClientsV3', options, [zoneId], {
    dispatchAction: (action, requestOptions) => action(zoneId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors } = entityReducer
      return !errors.length
    },
  })

  return null
}

const defaultState = {
  refresh: false,
  reset: false,
  searchFilter: '',
}

const mapResults = (filteredClients, linkedClients, zones) => {
  const results = Object.values(filteredClients).filter(client => !linkedClients.find(linked => linked.id === client.id))

    return {
      groups: [
        {
          key: 'clients',
          title: 'Clients',
          items: results.map((result) => {
            const { name, reference, zone } = result
            const linkedZone = Object.values(zones).find(x => x.reference === zone?.entity)

            return {
              ...result,
              key: reference,
              text: name,
              secondaryText: linkedZone?.name || '',
            }
          }),
        },
    ],
  }
}

const ClientLinking = (props) => {
  const { callbacks, linkedClients, zoneForm } = props

  const [state, setState] = useSetState(defaultState)
  const { refresh, reset, searchFilter } = state

  const { toggleUpdateLinkedClients } = callbacks

  const { zonesV3 } = useSelector(reduxState => reduxState.entities)
  const { result: { linkedClients: updatedLinkedClients } } = useSelector(reduxState => reduxState.clientsV3)

  const {
    entityState: {
      id,
      name,
      reference,
    },
  } = zoneForm

  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  useEffect(() => {
    setState({ searchFilter: debouncedSearchFilter })
  }, [debouncedSearchFilter])

  const searchFilterString = `"name":"${searchFilter.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: 50,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('clientsV3', 'loadClientsV3', options, [searchFilter, debouncedSearchFilter])

  const { filteredClientsV3, loading } = useClientsV3({
    searchFilter,
    reset,
  })

  useEffect(() => {
    if (updatedLinkedClients?.length && !linkedClients.length){
      const newList = [...linkedClients, ...updatedLinkedClients]
      toggleUpdateLinkedClients([...new Map(newList.map(item => [item.id, item])).values()])
    }
  }, [updatedLinkedClients])

  useEffect(() => {
    toggleUpdateLinkedClients(linkedClients)
    setState({ refresh: true })
  }, [])

  const toggleClient = async (client, type) => {
    const currentZone = Object.values(zonesV3).find(x => x.reference === client.zone?.entity)
    const updatedClient = { ...client, zone: { entity: reference || '' } }
    let newList = []

    if (type === 'add'){
      if (currentZone){
        newList = await swal.fire({
          title: 'Move Client to this Zone',
          html: `This action will move this client from ${currentZone?.name} to ${name || 'this zone'}.`
          + '<br/>Do you wish to proceed?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          confirmButtonColor: '#e2001a',
        }).then(({ value }) => {
          if (value){
            return [...linkedClients, updatedClient]
          }
          return linkedClients
        })
      } else {
        newList = [...linkedClients, updatedClient]
      }
    } else {
      newList = linkedClients.filter(x => x.id !== client.id)
    }

    toggleUpdateLinkedClients(newList)
  }

  return (
    <>
      <LinkingTags
        callbacks={{
          toggleItem: item => toggleClient(item, 'add'),
          toggleSearch: search => setState({ searchFilter: search }),
          removeItem: item => toggleClient(item),
        }}
        itemsDirection="column"
        label="Clients"
        linkedItems={linkedClients}
        loading={loading}
        secondLabel={`(${linkedClients.length})`}
        searchFilter={searchFilter}
        searchResults={mapResults(filteredClientsV3, linkedClients, zonesV3)}
      />
      {refresh && <Load zoneId={id} />}
    </>
  )
}

ClientLinking.propTypes = {
  callbacks: PropTypes.object,
  linkedClients: PropTypes.array,
  zoneForm: PropTypes.object,
}

export default ClientLinking
