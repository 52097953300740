/* eslint-disable consistent-return */
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'

import { Grid } from '@campaignhub/suit-theme'

import { toggleArray } from '@campaignhub/javascript-utils'
import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

import GridItem from './GridItem'

const defaultState = {
  currentlySelectedIds: [],
}

const GridView = (props) => {
  const {
    callbacks: { toggleUpdateSelectedIds },
    consolidateContent, contents, maxSelection, multipleSelection, selectedIds, showDetails,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { currentlySelectedIds } = state

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobStatuses } = entities

  const selectContent = (content) => {
    if (maxSelection){
      if (maxSelection <= selectedIds.length && !selectedIds.includes(content.id)){
        return (
          swal.fire({
            title: 'Maximum Number of<br/>Assets Selected',
            html: `This job requires only one (${maxSelection}) proof.<br/> 
            Please deliver ${maxSelection} asset${maxSelection > 1 ? 's' : ''} to the client.`,
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#e2001a',
          })
        )
      }
    }

    setState({
      currentlySelectedIds: toggleArray((selectedIds.includes(content.id)) ? selectedIds
        : selectedIds.filter(id => id !== content.id), content.id),
    })
  }

  useEffect(() => {
    toggleUpdateSelectedIds(currentlySelectedIds)
  }, [currentlySelectedIds])

  const fileUrl = contents.find(x => x.file)?.assetUrl

  return (
    <Grid
      gridAutoRows="1fr"
      gridGap="large"
      gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      maxHeight="572px"
      overflowY="auto"
    >
      {consolidateContent && contents.length > 0 && (
        <GridItem
          badgeColor={getServiceStatusColor(serviceJobStatuses[contents[0].serviceJobStatusId]?.name)}
          badgeText={serviceJobStatuses[contents[0].serviceJobStatusId]?.description}
          content={contents[0]}
          fileUrl={fileUrl ? `${fileUrl}` : null}
          iconName={serviceGroups[serviceJob.serviceGroupId]?.name}
          link={`/service-jobs/${serviceJob.id}/assets?assetId=${contents[0].id}`}
          name={serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting' ? 'Build Copy' : 'Consolidated File'}
          showDetails={showDetails}
        />
      )}
      {!consolidateContent && contents.map((content) => {
        const selected = selectedIds.includes(content.id)

        return (
          <GridItem
            badgeColor={getServiceStatusColor(serviceJobStatuses[content.serviceJobStatusId]?.name)}
            badgeText={serviceJobStatuses[content.serviceJobStatusId]?.description}
            callbacks={{ selectContent: () => selectContent(content) }}
            content={content}
            fileUrl={`${content.assetUrl}`}
            iconName={serviceGroups[serviceJob.serviceGroupId]?.name}
            link={`/service-jobs/${serviceJob.id}/assets?assetId=${content.id}`}
            multipleSelection={multipleSelection}
            name={content.file.originalName}
            selected={selected}
            showDescription
            showDetails={showDetails}
          />
        )
      })}
    </Grid>
  )
}

GridView.propTypes = {
  callbacks: PropTypes.object,
  consolidateContent: PropTypes.bool,
  contents: PropTypes.array,
  maxSelection: PropTypes.number,
  multipleSelection: PropTypes.bool,
  selectedIds: PropTypes.array,
  showDetails: PropTypes.bool,
}

export default GridView
