import React from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'

import AssetScreen from '@screens/AssetScreen'
import ErrorPage from '@screens/ErrorPage'
import FullAssetScreen from '@screens/FullAssetScreen'
import ServiceJob from '@screens/ServiceJob'

import Studio from '.'
import Dashboard from './packs/Dashboard'
import ServiceJobs from './packs/Dashboard/screens/ServiceJobs'

const StudioRoutes = () => {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<ServiceJobs />} />
      </Route>
      <Route path="/service-jobs" element={<Dashboard />}>
        <Route index element={<ServiceJobs />} />
        <Route path=":id" element={<ServiceJob userType="Studio" />} />
        <Route path=":id/assets" element={<AssetScreen />} />
      </Route>
      <Route path="/service-jobs/:id/assets/full" element={<FullAssetScreen />} />
    </Route>,
  ))

  return (
    <Studio>
      <RouterProvider router={router} />
    </Studio>
  )
}

export default StudioRoutes
