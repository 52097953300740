import React, { useContext } from 'react'
import PageContext from '@contexts/pageContext'

import { Box } from '@campaignhub/suit-theme'

import AssetCommentGroup from './components/AssetCommentGroup'

const QCTab = () => {
  const { groupedAssetComments } = useContext(PageContext)

  return (
    <Box flexDirection="column" alignItems="center" width="100%">
      <AssetCommentGroup groupName="Unresolved" comments={groupedAssetComments?.null || []} />
      <AssetCommentGroup groupName="Revised" comments={groupedAssetComments?.Revised || []} />
      <AssetCommentGroup groupName="Resolved" comments={groupedAssetComments?.Resolved || []} />
    </Box>
  )
}

export default QCTab
