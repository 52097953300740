import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box, FormField, DashboardModule,
} from '@campaignhub/suit-theme'

import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
import { requiredFields } from '@models/Zone/zone'

const GeneralDetails = (props) => {
  const { zoneForm, callbacks } = props

  const { setCustomErrors } = callbacks

  const {
    entityState,
    entityState: {
      name,
    },
    errors,
    handlers,
  } = zoneForm

  useEffect(() => {
    setCustomErrors(validateEntityStateCustomFields(entityState, requiredFields))
  }, [entityState])

  return (
    <DashboardModule title="General Information" boxProps={{ marginBottom: 'large' }}>
      <Box flexDirection="column" padding="large">
        <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']} width={['100%', 'calc(50% - 4px)']}>
          <FormField
            boxProps={{ flex: 1 }}
            label="Name"
            errorMessage={errors.name}
          >
            <input name="name" type="text" value={name} {...handlers} />
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
  )
}

GeneralDetails.propTypes = {
  zoneForm: PropTypes.object,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
}

export default GeneralDetails
