import useReduxAction from '@hooks/useReduxAction'
import useUserRoles from '@hooks/useUserRoles'
import useServiceGroups from '@hooks/useServiceGroups'
import useServiceJobStatuses from '@hooks/useServiceJobStatuses'
import useNotificationTypes from '@hooks/useNotificationTypes'

const Studio = (props) => {
  const { children } = props

  useReduxAction('assetCommentTypes', 'loadAssetCommentTypes', {})
  useReduxAction('resourceTypes', 'loadResourceTypes', {})
  useReduxAction('wordReplacements', 'loadWordReplacements', {})
  useReduxAction('emailReplacements', 'loadEmailReplacements', {})
  useUserRoles({ performHttpRequests: true })
  useServiceGroups({ performHttpRequests: true })
  useServiceJobStatuses({ performHttpRequests: true })
  useNotificationTypes({ performHttpRequests: true })

  return children
}

export default Studio
