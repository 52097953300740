import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import userTypes from '@functions/userTypes'

import {
  Box, Grid, ListItem, StatusBadge, Text, DashboardModule,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'

import Icons from '@components/Icons'
import blankState from '@components/assets/blankState.svg'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

const ServiceJobList = (props) => {
  const { serviceJobs, showUsers, showProvider, showQC, showRejection } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const { currentUser: { timeZone, userRole } } = useCurrentUser()

  if (!serviceJobs.length){
    return (
      <DashboardModule.BlankState imageUrl={blankState}>
        <DashboardModule.BlankState.Title>No Jobs Available</DashboardModule.BlankState.Title>
        <DashboardModule.BlankState.Paragraph>
          Try adjusting your filters.
        </DashboardModule.BlankState.Paragraph>
      </DashboardModule.BlankState>
    )
  }

  return (
    <Box flexDirection="column" maxHeight="82vh" overflowY="auto">
      {serviceJobs.map((serviceJob) => {
        const now = DateTime.now()
        const deadline = DateTime.fromISO(
          showUsers ? serviceJob.clientDeadline : serviceJob.dateService,
          { zone: timeZone },
        )
        const pastDeadline = now > deadline

        const providerUsername = serviceJob.details?.find(x => x.entityFieldType.name === 'ServiceUsername')?.value

        const editors = serviceJob.users.filter(user => user.serviceJobUserRoleId === 1)
        const qc = serviceJob.users.filter(user => user.serviceJobUserRoleId === 2)
        const rejection = serviceJob.users.filter(user => user.serviceJobUserRoleId === 3)

        return (
          <Link to={`/service-jobs/${serviceJob.id}`} target="_blank" style={{ textDecoration: 'none' }}>
            <ListItem
              boxProps={{
                borderBottom: '1px solid',
                gridGap: 'large',
                padding: 'large',
              }}
              flexDirection={['column', 'column', 'row']}
              forceBorderBottom
              justifyContent="center"
              key={serviceJob.id}
              showAngleIcon
            >
              <Box flexDirection="row" width={showUsers ? ['100%', '100%', '45%'] : ['100%', '80%']}>
                <Box
                  alignItems="center"
                  color="bodyFontLightColor"
                  display="grid"
                  justifyContent="center"
                  marginRight="large"
                  width="auto"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name || 'Photography'} size={23} />
                </Box>
                <Box flexDirection="column" justifyContent="center">
                  <Text fontSize="small" marginBottom="medium">
                    {serviceJob.campaign?.name}
                  </Text>
                  <Text fontSize="xsmall" marginBottom="medium" color="bodyFontLightColor">
                    {serviceJob.client?.name}
                  </Text>
                  <Box display="block" width="auto">
                    <StatusBadge
                      boxProps={{ marginRight: 'small' }}
                      color="#282B42"
                      ghost
                      text={serviceJob.service?.name}
                    />
                    <StatusBadge
                      boxProps={{ marginRight: 'small' }}
                      color={getServiceStatusColor(serviceJob.status?.name)}
                      ghost
                      text={serviceJob.status?.description}
                    />
                    {serviceJob.client?.classification === 'VIP' && <StatusBadge color="red" ghost text="VIP" />}
                  </Box>
                </Box>
              </Box>
              {showUsers && (
                <Grid
                  gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                  gridGap="small"
                  width={['100%', '100%', '35%']}
                >
                  {showProvider && (
                    <Box flexDirection="column" justifyContent="center">
                      <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Provider</Text>
                      {!providerUsername || JSON.parse(providerUsername).value.trim() === ''
                        ? <StatusBadge fontSize="small" color="green" text="Available" />
                        : <Text fontSize="small">{JSON.parse(providerUsername).value || ''}</Text>}
                    </Box>
                  )}
                  <Box flexDirection="column" justifyContent="center">
                    <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Editor</Text>
                    {!editors.length
                      ? <StatusBadge fontSize="small" color="green" text="Available" />
                      : editors.map(serviceJobUser => (
                        <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                      ))}
                  </Box>
                  {showQC && (
                    <Box flexDirection="column" justifyContent="center">
                      <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">QC</Text>
                      {!qc.length
                        ? <StatusBadge fontSize="small" color="green" text="Available" />
                        : qc.map(serviceJobUser => (
                          <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                        ))}
                    </Box>
                  )}
                  {showRejection && (
                    <Box flexDirection="column" justifyContent="center">
                      <Text fontSize="xsmall" marginBottom="small" color="bodyFontLightColor">Rejection</Text>
                      {!rejection.length
                        ? <StatusBadge fontSize="small" color="green" text="Available" />
                        : rejection.map(serviceJobUser => (
                          <Text key={serviceJobUser.user.firstName} fontSize="small">{serviceJobUser.user.firstName} {serviceJobUser.user.lastName}</Text>
                        ))}
                    </Box>
                  )}
                </Grid>
              )}
              <Box flexDirection="column" justifyContent="center" width={['100%', '100%', '20%']} textAlign={['left', 'left', 'right']}>
                <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>{deadline.invalid ? '' : deadline.toFormat('y-LL-dd')}</Text>
                <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>{deadline.invalid ? '' : deadline.toFormat('hh:mm a ccc')}</Text>
                <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'} marginBottom="small">
                  {deadline.invalid ? '' : deadline.toRelativeCalendar()}
                </Text>
                {(userRole?.userTypeId === userTypes?.find(x => x.name === 'OnSite')?.id && serviceJob.service?.onSite) && (
                  <Text fontSize="small" color={pastDeadline ? 'bodyFontLightColor' : 'bodyFontColor'}>
                    {` (${!serviceJob.serviceJobLength ? 0 : serviceJob.serviceJobLength} minutes)`}
                  </Text>
                )}
              </Box>
            </ListItem>
          </Link>
        )
      })}
    </Box>
  )
}

ServiceJobList.propTypes = {
  serviceJobs: PropTypes.array.isRequired,
  showUsers: PropTypes.bool,
  showProvider: PropTypes.bool,
  showQC: PropTypes.bool,
  showRejection: PropTypes.bool,
}

export default ServiceJobList
