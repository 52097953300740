import React, { useContext } from 'react'
import ImageMarker from 'react-image-marker'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'

import { Box, Grid, Text } from '@campaignhub/suit-theme'

import useUsers from '@hooks/useUsers'
import Icons from '@components/Icons'
import CustomMarker from './components/CustomMarker'
import ImageSlider from './components/ImageSlider'

import styles from './styles.module.scss'

const AssetMarker = (props) => {
  const { componentProps } = props
  const {
    contents, markers, showAllMarkers, setState,
  } = componentProps

  const { assignedUserRole, currentUser, selectedAsset, serviceJob } = useContext(PageContext)
  const { users } = useUsers()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const latestVersion = selectedAsset?.histories?.reduce((x, y) => ((x.version > y.version) ? x : y))

  return (
    <Box flexDirection="column">
      <Text fontSize="medium" fontWeight="500" marginBottom="medium">
        Assets
      </Text>
      <Grid
        gridTemplateColumns="1fr"
        gridAutoRows="min-content"
        alignItems="center"
        justifyContent="center"
        style={{ cursor: 'pointer' }}
        width="100%"
        gridGap="medium"
      >
        <Grid
          gridTemplateColumns="1fr"
          gridAutoRows="min-content"
          alignContent="center"
          alignItems="center"
          gridGap="medium"
          justifyContent="center"
          width="100%"
          height={[430, '70vh']}
        >
          {selectedAsset?.file?.previewGeneratedAt ? (
            <ImageMarker
              markers={showAllMarkers ? markers : markers.filter(x => x.status === null || !x.status)}
              markerComponent={markerProps => CustomMarker({
                markerProps, markers, markerSetState: setState,
              })}
              onAddMarker={(marker) => {
                if (['ProductionManager'].includes(users[currentUser.id]?.userRole.name) || assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'QualityControl')){
                  setState({ markers: [...markers, marker] })
                }
              }}
              src={selectedAsset?.previewUrl}
              extraClass={styles.markerImg}
            />
          ) : (
            <Grid alignItems="center" justifyContent="center">
              <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
            </Grid>
          )}
          <Text color="bodyFontLightColor" fontSize="small" textAlign="center">
            Version {selectedAsset?.version}  {(latestVersion?.version === selectedAsset?.version) ? '(Current)' : ''}
          </Text>
        </Grid>
        <ImageSlider assetSetState={setState} contents={contents} selectedAssetId={selectedAsset?.id} />
      </Grid>
    </Box>
  )
}

AssetMarker.propTypes = {
  componentProps: PropTypes.object,
}

export default AssetMarker
