import React, { useEffect, useState } from 'react'

import { getUserInfo } from '@functions/staticWebAppUserInfo'

import Login from '@auth/Login'
import AuthorizationController from '@auth/AuthorizationController'
// import SWAController from '@auth/SWAController'
// When logging in the SWA user info is not instantly available
// We wait and check 0.1 second later for it to be available

const waitForUserInfo = (setSwaUserInfo) => {
  const intervalId = setInterval(() => {
    const userInfo = getUserInfo()
    if (userInfo){
      clearInterval(intervalId)
      setSwaUserInfo(userInfo)
    }
  }, 100)
}

const AuthenticationController = () => {
  const [swaUserInfo, setSwaUserInfo] = useState(null)

  useEffect(() => {
    waitForUserInfo(setSwaUserInfo)
  }, [])

  if (swaUserInfo){
    return <AuthorizationController />
  }

  return <Login />
}

export default AuthenticationController
