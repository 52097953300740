import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import {
  Box, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import useServiceJobUser from '@hooks/useServiceJobUser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import IconProfile from '@components/IconProfile'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const deleteServiceJobUser = (serviceJobUser, deleteFn) => {
  swal.fire({
    title: 'Remove Assigned User',
    text: 'Are you sure you want remove this user?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#e2001a',
    showClass: 'slide-from-top',
  }).then(({ value }) => {
    if (value){
      deleteFn(serviceJobUser).then(({ success, errors }) => {
        if (!success && errors){
          toast.warning(errors[0])
        }
      })
    }
  })
}

const AssignedUser = (props) => {
  const { serviceJobUser, showDetails } = props

  const {
    callbacks: {
      deleteServiceJobUser: deleteFn,
    },
  } = useServiceJobUser(serviceJobUser)

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      style={{ cursor: 'pointer' }}
      key={serviceJobUser.id}
    >
      <Box flexDirection="row">
        <IconProfile
          name={`${serviceJobUser.user.firstName} ${serviceJobUser.user.lastName}`}
          position={serviceJobUser.serviceJobUserRole?.description}
        />
        <Box flexShrink="0" alignItems="center" justifyContent="flexEnd" width="auto" color="bodyFontLightColor">
          <FontAwesomeIcon icon={faTimes} onClick={() => deleteServiceJobUser(serviceJobUser, deleteFn)} />
        </Box>
      </Box>
      {showDetails && (
        <Box flexDirection="row" alignItems="center" marginTop="medium">
          <Box flexDirection="column" alignItems="center" width="30%">
            <CircularProgressbar
              counterClockwise
              text="1:58:43"
              value={30}
              styles={buildStyles({
                textColor: '#404041',
                pathColor: '#FFA500',
              })}
            />
          </Box>
          <Box flexDirection="column" marginLeft="medium">
            <Text marginBottom="medium" fontSize="xsmall">6 Images Assigned</Text>
            <Box flexDirection="row" alignItems="center">
              <StatusBadge color="lightgray" text="In Progress" />
            </Box>
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

AssignedUser.propTypes = {
  serviceJobUser: PropTypes.object,
  showDetails: PropTypes.bool,
}

export default AssignedUser
