import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { getUserInfo } from '@functions/staticWebAppUserInfo'
import { baseURL } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    requestOptions = {},
    maxFileSize,
    callbacks,
    fieldName,
    endpoint,
    loadSearchParams,
  } = options

  const { loadFn } = callbacks

  const swaKey = Buffer.from(JSON.stringify(getUserInfo())).toString('base64')

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: true,
    method: 'put',
    restrictions: {
      allowedFileTypes,
      maxFileSize,
      maxNumberOfFiles: 1,
    },
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/${endpoint}${requestOptions.id}`,
      method: 'PUT',
      headers: {
        'x-ms-client-principal': swaKey,
      },
      formData: true,
      fieldName,
      timeout: 0,
      getResponseError(){
        toast.warning('Failed to upload')
      },
    })
    .on('restriction-failed', (file, error) => {
      toast.warning(`${error}.  File: ${file.name}`)
    })
    .on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        ...requestOptions,
      })
    })
    .on('upload-error', (file) => {
      toast.warning(`Failed to upload ${file.name}`)
    })
    .on('complete', async () => {
      await loadFn(requestOptions.id, new URLSearchParams(loadSearchParams))
      uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions']
  const optionKeys = Object.keys(options)

  return requiredOptions.every(key => optionKeys.includes(key))
}

function useUppyUploadVersion(options = {}){
  // eslint-disable-next-line consistent-return
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUploadVersion
