import { useThunkDispatch } from '@campaignhub/react-hooks'
import * as timeTrackerActions from '@redux/modules/timetracker'

const clockIn = (dispatch) => {
  const { clockIn: clockInFn } = timeTrackerActions
  return dispatch(clockInFn())
}

const clockOut = (dispatch) => {
  const { clockOut: clockOutFn } = timeTrackerActions
  return dispatch(clockOutFn())
}

const startBreak = (dispatch) => {
  const { startBreak: startBreakFn } = timeTrackerActions
  return dispatch(startBreakFn())
}

const endBreak = (dispatch) => {
  const { endBreak: endBreakFn } = timeTrackerActions
  return dispatch(endBreakFn())
}

const useTimeTracker = () => {
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      clockIn: () => clockIn(dispatch),
      clockOut: () => clockOut(dispatch),
      startBreak: () => startBreak(dispatch),
      endBreak: () => endBreak(dispatch),
    },
  }
}

export default useTimeTracker
