export function getRequestOptions(type, serviceJobOptions, selectedResourceType){
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        Description: '',
        Sort: 0,
        Tags: {},
        ...serviceJobOptions,
        ResourceTypeId: selectedResourceType,
      }
    case 'ASSETS':
      return {
        Description: 'new upload',
        ...serviceJobOptions,
      }
    default:
      return null
  }
}

export function getUppyOptions(type, allowedFileTypes, serviceJobOptions, setState, callbacks, selectedResourceType, filesToUploadCount){
  const requestOptions = getRequestOptions(type, serviceJobOptions, selectedResourceType)
  switch (type.toUpperCase()){
    case 'RESOURCES':
      return {
        allowedFileTypes,
        requestOptions,
        maxFileSize: 500000000,
        callbacks,
        fieldName: 'FormFile',
        endpoint: 'v1/resources/',
        loadSearchParams: [
          ['includes', 'file'],
        ],
        validateFileCount: filesToUploadCount,
      }
    case 'ASSETS':
      return {
        allowedFileTypes,
        requestOptions,
        maxFileSize: 0,
        callbacks,
        fieldName: 'FormFile',
        endpoint: 'v1/assets/',
        loadSearchParams: [
          ['serviceJobs', requestOptions.serviceJobId],
          ['includes', 'comments'],
          ['includes', 'file'],
          ['includes', 'histories'],
        ],
        validateFileCount: 0,
      }
    default:
      return {
        fieldName: '',
        endpoint: '',
      }
  }
}

export function getComponentCallbacks(component, setState){
  const componentsCallbacks = {
    UPPY: {
      addRecentlyUploadedBatch: (uploadedList, failedList) => setState({ lastUploadedBatch: uploadedList, lastFailedBatch: failedList }),
      doneUpload: status => setState({ doneUpload: status }),
      setFilesToUploadCount: count => setState({ filesToUploadCount: count }),
    },
    RECENTLYUPLOADEDLIST: {
      removeRecentlyDeleted: id => setState({ lastDeletedId: id }),
    },
  }

  return componentsCallbacks[component.toUpperCase()] || {}
}
