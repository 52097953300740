import React from 'react'
import { Outlet } from 'react-router-dom'

import { MainContent } from '@campaignhub/suit-theme'
import useFreshDesk from '@hooks/useFreshDesk'
import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@suiteThemeCustom/TopBar/components/LoggedInUser'
import TimeTracker from '@suiteThemeCustom/TopBar/components/TimeTracker'
import TopBar from '@suiteThemeCustom/TopBar'

import MainNavigation from '@sections/OnSite/components/MainNavigation'
import ServiceJobsSearch from '@components/ServiceJobsSearch'

const ProviderDiary = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  useFreshDesk()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<ServiceJobsSearch />}
        timeTrackerComponent={<TimeTracker />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <Outlet />
      </MainContent>
    </>
  )
}

export default ProviderDiary
