import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Box, SidebarModal } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import AssetCommentGroup from './components/AssetCommentGroup'

const AssetQCModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { groupedAssetComments } = useContext(PageContext)

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="QC" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" alignItems="center" width="100%">
          <AssetCommentGroup groupName="Unresolved" comments={groupedAssetComments?.null || []} />
          <AssetCommentGroup groupName="Revised" comments={groupedAssetComments?.Revised || []} />
          <AssetCommentGroup groupName="Resolved" comments={groupedAssetComments?.Resolved || []} />
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetQCModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetQCModal.defaultProps = {
  modalKey: 'AssetQCModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetQCModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
