import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import Carousel from 'react-elastic-carousel'
import PropTypes from 'prop-types'

import { Box, Image } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import Icons from '@components/Icons'
import PageContext from '@contexts/pageContext'

const ImageSlider = (props) => {
  const { assetSetState, contents, selectedAssetId } = props

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <Box flexDirection="column">
      <Carousel
        breakPoints={[
          { width: 1, itemsToShow: 3 },
          { width: 550, itemsToShow: 5, itemsToScroll: 5 },
          { width: 850, itemsToShow: 8, itemsToScroll: 8 },
          { width: 1000, itemsToShow: 10, itemsToScroll: 10 },
        ]}
        pagination={false}
        renderArrow={({ type, onClick, isEdge }) => (
          <Box
            alignItems="center"
            color="bodyFontColor"
            height={80}
            onClick={onClick}
            opacity={isEdge ? 0.5 : 1}
            pointerEvents={isEdge ? 'none' : 'auto'}
            styles={{
              cursor: isEdge ? 'none' : 'pointer',
            }}
            width={20}
          >
            <FontAwesomeIcon icon={type === 'PREV' ? faChevronLeft : faChevronRight} />
          </Box>
        )}
        showEmptySlots
      >
        {contents.map(content => (
          <Box key={content.id} onClick={() => assetSetState({ selectedAsset: content })}>
            <Image
              boxProps={{
                backgroundColor: 'hoverLightGrey',
                border: '2px solid',
                borderColor: selectedAssetId === content.id ? 'blue' : 'white',
              }}
              height={80}
              url={content.file?.previewGeneratedAt ? content.previewUrl : ''}
              width={110}
            >
              {!content.file?.previewGeneratedAt && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={25} width="100%" />
                </Box>
              )}
            </Image>
          </Box>
        ))}
      </Carousel>
    </Box>
  )
}

ImageSlider.propTypes = {
  assetSetState: PropTypes.func,
  contents: PropTypes.array,
  selectedAssetId: PropTypes.number,
}

export default ImageSlider
