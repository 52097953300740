import React, { useContext } from 'react'

import { Box, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import AssetComment from './components/AssetComment'

const ClientTab = () => {
  const { clientComments: comments } = useContext(PageContext)

  return (
    <Box flexDirection="column" alignItems="center" paddingX="large" width="100%">
      {comments?.sort((a, b) => (a.id > b.id ? -1 : 1)).map(comment => (
        <AssetComment key={comment.id} comment={comment} />
      ))}
      {!comments?.length && (
        <Box paddingY="large" justifyContent="center">
          <Text fontSize="xsmall" color="bodyFontLightColor">No comments</Text>
        </Box>
      )}
    </Box>
  )
}

export default ClientTab
