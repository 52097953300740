import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import {
  Box, Button, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import useServiceJob from '@hooks/useServiceJob'

import custom from '@styles/custom.module.scss'
import { getEntityByName } from '@functions/getEntityByName'
import InputComponent from './components/InputComponent'
import TextAreaComponent from './components/TextAreaComponent'

const assignServiceJobStatus = (serviceJobParam, createFn, toggleCopyFieldState) => {
  createFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }
  })

  toggleCopyFieldState()
}

const defaultState = {
  showFilter: false,
}

const CopyTemplate = (props) => {
  const { componentProps: { callbacks: {
    toggleCopyFieldState, toggleUpdateCopyDetails,
  }, contents, copyFieldDisabled }, serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const { showFilter } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { assetComments, serviceJobStatuses } = entities
  const {
    callbacks: {
      assignServiceJobStatus: createFn,
    },
  } = useServiceJob({ id: serviceJob.id })

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const componentMapping = {
    input: InputComponent,
    textarea: TextAreaComponent,
    DefaultView: () => (
      <Text fontSize="small" color="bodyFontLightColor" marginBottom="small">
        Input type does not exist.
      </Text>
    ),
  }

  const enableCopyEditing = () => {
    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: getEntityByName(serviceJobStatuses, 'InProduction').id,
      workflowComment: 'Updated from CH Copy Assets',
      assets: contents.map(x => x.id),
    }

    if (getEntityByName(serviceJobStatuses, 'InProduction').id > serviceJob.serviceJobStatusId){
      assignServiceJobStatus(serviceJobParam, createFn, toggleCopyFieldState)
    } else {
      toggleCopyFieldState()
    }
  }

  const filteredCopyComments = copyDetailId => Object.values(assetComments)?.reduce((filtered, comment) => {
    if (comment.details?.some(x => x.entityFieldType?.name === 'CopyDetailId' && JSON.parse(x.value).value === copyDetailId)){
      filtered.push(comment)
    }
    return filtered
  }, [])

  return (
    <Box flexDirection="column" className={custom.scroll}>
      <Box alignItems="center" flexDirection="row" marginBottom="medium">
        <Text fontSize="medium" fontWeight="500" width="100%">
          Assets
        </Text>
        {contents.length > 0 && (
          <Button
            buttonStyle="ghostEdit"
            onClick={() => enableCopyEditing()}
            size="medium"
            width="auto"
            icon={<FontAwesomeIcon icon={faPencil} />}
            height={37}
          />
        )}
      </Box>
      <Box
        flexDirection="column"
        border="1px solid"
        borderColor="lineColor"
        borderRadius={5}
        height="80vh"
        overflowY="auto"
        padding="large"
      >
        {contents.filter(x => x.copy.selected)
          .sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1))
          .map((content) => {
          const { copy } = content

          return (
            <Box flexDirection="column" key={copy.id}>
              <Box flexDirection="row" alignItems="center" marginBottom="large">
                <Text fontSize="medium" fontWeight="500" marginRight="small">
                  {copy.copyTemplate.displayName}
                </Text>
                <StatusBadge color="green" ghost text="Selected" />
              </Box>
              {copy.copyDetails.map((detail) => {
                const Component = componentMapping[detail.copyTemplateField.bulletPoint ? 'input' : 'textarea'] || componentMapping.DefaultView

                return (
                  <Component
                    key={detail.id}
                    assetId={content.id}
                    callbacks={{ toggleUpdateCopyDetailValue: value => toggleUpdateCopyDetails(content, detail.id, value) }}
                    disableCopyField={copyFieldDisabled}
                    copyProps={detail}
                    comments={filteredCopyComments(detail.id) || []}
                  />
                )
              })}
            </Box>
          )
        })}
        {contents.filter(x => !x.copy.selected)
          .sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1))
          .map((content) => {
          const { copy } = content

          return (
            <Box flexDirection="column" key={copy.id}>
              <Text fontSize="medium" fontWeight="500" marginBottom="large">
                {copy.copyTemplate.displayName}
              </Text>
              {copy.copyDetails.map((detail) => {
                const Component = componentMapping[detail.copyTemplateField.bulletPoint ? 'input' : 'textarea'] || componentMapping.DefaultView

                return (
                  <Component
                    key={detail.id}
                    assetId={content.id}
                    callbacks={{ toggleUpdateCopyDetailValue: value => toggleUpdateCopyDetails(content, detail.id, value) }}
                    disableCopyField={copyFieldDisabled}
                    copyProps={detail}
                    comments={filteredCopyComments(detail.id) || []}
                  />
                )
              })}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

CopyTemplate.propTypes = {
  componentProps: PropTypes.object,
  serviceJob: PropTypes.object,
}

export default CopyTemplate
