import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, Link, ListItem, StatusBadge, SidebarModal, Text,
} from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-light-svg-icons'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'

const AssetHistoryModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const {
    currentUser: { timeZone }, histories, serviceJob,
  } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Asset" titleSecondLine="History" />
      <SidebarModal.Content boxProps={{ padding: 0 }} hasFooter={false}>
        <Box flexDirection="column" alignItems="center" paddingX="large" width="100%">
          {histories?.sort((a, b) => (a.id > b.id ? -1 : 1)).map(history => (
            <ListItem
              boxProps={{
                alignItems: 'center',
                paddingY: 'small',
                borderBottom: '1px dashed',
              }}
              flexDirection="row"
              disableHover
              key={history.id}
            >
              <Box flexDirection="row" justifyContent="space-between" paddingBottom="small">
                <Box flexDirection="column" marginY="medium">
                  <Text fontSize="small" fontWeight="500" marginBottom="small">
                    {DateTime.fromISO(history.createdAt, { zone: timeZone }).toFormat('dd LLLL y, hh:mm a')}
                  </Text>
                  <StatusBadge
                    color={getServiceStatusColor(serviceJobStatuses[serviceJob.serviceJobStatusId]?.name)}
                    text={serviceJobStatuses[history.serviceJobStatusId]?.description}
                  />
                  <Text color="bodyFontLightColor" fontSize="xsmall" paddingTop="small">
                    {history.createdBy.firstName} {history.createdBy.lastName}
                  </Text>
                </Box>
                <Box color="bodyFontLightColor" marginRight="medium" width="auto" alignItems="center">
                  <Link href={history.assetUrl}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Link>
                </Box>
              </Box>
            </ListItem>
          ))}
          {!histories?.length && (
            <Box paddingY="large" justifyContent="center">
              <Text fontSize="xsmall" color="bodyFontLightColor">No history available</Text>
            </Box>
          )}
        </Box>
      </SidebarModal.Content>
    </SidebarModal>
  )
}

AssetHistoryModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

AssetHistoryModal.defaultProps = {
  modalKey: 'AssetHistoryModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <AssetHistoryModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
