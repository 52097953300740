/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Box, Button, DashboardModule, FormField, Tag, Text,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faStar, faTimes } from '@fortawesome/pro-light-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'
import useBlockout from '@hooks/useBlockout'
import useFlags from '@hooks/useFlags'
import useReduxAction from '@hooks/useReduxAction'
import useZonesV3 from '@hooks/useZonesV3'

import Blockouts from '@components/Blockouts'
import LinkingTags from '@components/LinkingTags'
import Scheduler from '@components/Scheduler'

import mapEntityToWorkbaseReferenceArray from '@functions/mapEntityToWorkbaseReferenceArray'
import mapServiceTypeResults from '@functions/mapServiceTypeResults'

const RefreshBlockouts = (props) => {
  const { setState, userId } = props

  const options = {
    filter: `{ 'user': 'User/${userId}'}`,
    size: 100,
    orderBy: '{ \'finish\': \'desc\' }',
  }

  useReduxAction('blockouts', 'loadBlockouts', options, [userId])

  setState({ refresh: false })
  return null
}

const LoadZones = (props) => {
  const { searchFilter } = props

  const searchFilterString = `"name":"%${searchFilter}%"`
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}}`).replace(/\s+/g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('zonesV3', 'loadZonesV3', options, [searchFilter])

  return null
}

const defaultState = {
  bookableServicesSelected: [],
  linkedServiceCategories: [],
  linkedZones: [],
  primaryCategory: '',
  refresh: true,
  reset: false,
  searchBookableServiceFilter: '',
  searchServiceTypeFilter: '',
  searchZoneFilter: '',
  servicesSelected: [],
  slotsSelected: {},
  zoneRefresh: false,
}

const mapZoneResults = (filteredZones, linkedZones) => {
  const results = Object.values(filteredZones).filter(zone => !linkedZones.find(linked => linked.id === zone.id))

  return {
    groups: [
      {
        key: 'zones',
        title: 'Zones',
        items: results.map((result) => {
          const { name, reference } = result

          return {
            ...result,
            key: reference,
            text: name,
          }
        }),
      },
    ],
  }
}

const providerCallbacks = (component, setState) => {
  const componentCallbacks = {
    Scheduler: {
      updateSlots: (slots) => {
        setState({ slotsSelected: { ...slots } })
      },
    },
  }

  return componentCallbacks[component] || {}
}

const ProviderDetail = (props) => {
  const { userForm, customErrors } = props

  const [state, setState] = useSetState(defaultState)
  const {
    bookableServicesSelected,
    primaryCategory,
    refresh,
    reset,
    linkedServiceCategories,
    linkedZones,
    searchBookableServiceFilter,
    searchServiceTypeFilter,
    searchZoneFilter,
    servicesSelected,
    slotsSelected,
    zoneRefresh,
  } = state

  const {
    entityState,
    entityState: {
      provider,
      provider: {
        address: {
          address,
          suburb,
          postcode,
          state: stateAddress,
        },
        flags,
        slots,
        minimum_slots,
        contact_number,
        virtual_contact_number,
        zones,
      },
    },
    handlers,
    setEntityState,
  } = userForm

  const { callbacks: { createOrEditBlockout } } = useBlockout()

  const { result: statesV3 } = useSelector(reduxState => reduxState.statesV3)

  const entities = useSelector(reduxState => reduxState.entities)
  const { blockouts, serviceCategories, services } = entities

  const blockoutEntityReducer = useSelector(reduxState => reduxState.blockouts)
  const { loading: blockoutsLoading } = blockoutEntityReducer

  const { result: providerFlagsV3 } = useSelector(reduxState => reduxState.providerFlagsV3)

  const getLinkedCategories = (linkedBookableServices) => {
    const linkedCategories = []

    linkedBookableServices.map((service) => {
      const serviceDetails = Object.values(services).find(x => x.workbaseReference === service)
      const serviceCategory = Object.values(serviceCategories).find(category => category.services.includes(serviceDetails?.id))
      if (serviceCategory) linkedCategories.push(serviceCategory.workbaseReference)
    })

    return [...new Set(linkedCategories)]
  }

  useEffect(() => {
    setState({
      bookableServicesSelected: entityState.provider?.bookable_services || [],
      primaryCategory: entityState.provider?.primary_category,
      servicesSelected: entityState.provider?.services_selected || [],
      linkedServiceCategories: getLinkedCategories(entityState.provider?.bookable_services || []),
    })
  }, [])

  useEffect(() => {
    setState({
      linkedZones: [],
      zoneRefresh: true,
    })
  }, [provider.id])

  useEffect(() => {
    if (Object.keys(slotsSelected).length > 0){
      provider.slots = slotsSelected
      setEntityState({ provider })
    }
  }, [slotsSelected])

  const { zonesV3, filteredZonesV3, loading: zoneLoading } = useZonesV3({
    searchFilter: searchZoneFilter,
    reset,
  })

  useEffect(() => {
    if (zones?.length && !linkedZones.length){
      const newList = []

      zones.map((zone) => {
        const currentZone = Object.values(zonesV3).find(x => x.reference === zone.entity)
        return newList.push({ ...zone, ...currentZone })
      })

      setState({ linkedZones: newList })
    }
  }, [zones, zonesV3])

  const toggleService = (service, type) => {
    let newList = []

    if (type === 'add'){
      newList = [...servicesSelected, service.workbaseReference]
    } else {
      newList = servicesSelected.filter(x => x !== service.workbaseReference)
    }

    provider.services = newList
    setState({ servicesSelected: newList })
    setEntityState({ provider })
  }

  const toggleBookableService = (service, type) => {
    let newList = []

    if (type === 'add'){
      newList = [...bookableServicesSelected, service.workbaseReference]
    } else {
      newList = bookableServicesSelected.filter(x => x !== service.workbaseReference)
    }

    const newCategoryList = getLinkedCategories(newList)
    const primary = newCategoryList.includes(primaryCategory) ? primaryCategory : ''
    provider.primary_category = primary
    provider.bookable_services = newList

    setState({
      bookableServicesSelected: newList,
      linkedServiceCategories: newCategoryList,
      primaryCategory: primary,
    })
    setEntityState({ provider })
  }

  const toggleZone = (zone, type) => {
    let newList = []

    if (type === 'add'){
      const updatedZone = zone
      updatedZone.entity = zone.reference

      newList = [...linkedZones, updatedZone]
    } else {
      newList = linkedZones.filter(x => x.id !== zone.id)
    }

    provider.zones = newList
    setState({ linkedZones: newList })
    setEntityState({ provider })
  }

  const { callbacks: { editUserFlags } } = useFlags()

  return (
    <>
      <DashboardModule title="Provider Details">
        <Box flexDirection="column" padding="large">
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
              label="Address"
            >
              <input name="provider.address.address" type="text" value={address} {...handlers} />
            </FormField>
          </Box>
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
              label="Suburb"
            >
              <input name="provider.address.suburb" type="text" value={suburb} {...handlers} />
            </FormField>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
              label="State"
            >
              <select
                data-validate-field-on="change"
                name="provider.address.state"
                value={stateAddress}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {Object.values(statesV3).filter(x => x !== 'Unknown').map(stateV3 => <option value={stateV3} key={stateV3}>{stateV3}</option>)}
              </select>
            </FormField>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0] }}
              label="Postcode"
            >
              <input name="provider.address.postcode" type="text" value={postcode} {...handlers} />
            </FormField>
          </Box>
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
              label="Contact Number"
            >
              <input name="provider.contact_number" type="text" value={contact_number} {...handlers} />
            </FormField>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
              label="Virtual Contact Number"
            >
              <input name="provider.virtual_contact_number" type="text" value={virtual_contact_number} {...handlers} />
            </FormField>
            <FormField
              boxProps={{ flex: 3, paddingBottom: ['large', 0] }}
              label="Minimum Slots"
              errorMessage={customErrors['provider.minimum_slots']}
            >
              <input
                name="provider.minimum_slots"
                type="number"
                value={minimum_slots}
                {...handlers}
              />
            </FormField>
          </Box>
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
              label={(
                <Box flexDirection="row" alignItems="center">
                  <Text
                    color="bodyFontLightColor"
                    fontSize="xsmall"
                    letterSpacing="1px"
                    marginRight="small"
                  >
                    Flags
                  </Text>
                  <Tag
                    boxProps={{
                      backgroundColor: '#43ac6a!important',
                      fontSize: 'xsmall',
                      onClick: () => { editUserFlags('provider') },
                      width: 'fit-content',
                    }}
                  >
                    <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Text>
                  </Tag>
                </Box>
              )}
            >
              <Box flexWrap="wrap" gridGap="small">
                {flags.map(flag => (
                  <Tag
                    key={flag}
                    boxProps={{
                      width: 'fit-content',
                      fontSize: 'xsmall',
                      onClick: () => {
                        provider.flags = provider.flags.filter(x => x !== flag)
                        setEntityState({ provider })
                      },
                    }}
                  >
                    <Text color="bodyFontLightColor" style={{ cursor: 'pointer' }} title={providerFlagsV3.find(x => x.value === flag)?.description}>
                      {providerFlagsV3.find(x => x.value === flag)?.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
                    </Text>
                  </Tag>
                ))}
              </Box>
            </FormField>
          </Box>
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{
                flex: 1,
                ...linkedServiceCategories.length > 0 && { marginRight: [0, 'medium'] },
              }}
            >
              <LinkingTags
                callbacks={{
                  toggleItem: item => toggleZone(item, 'add'),
                  toggleSearch: search => setState({ searchZoneFilter: search }),
                  removeItem: item => toggleZone(item),
                }}
                fullWidth={linkedServiceCategories.length > 0}
                label="Zones"
                linkedItems={linkedZones}
                loading={zoneLoading}
                searchFilter={searchZoneFilter}
                searchResults={mapZoneResults(filteredZonesV3, linkedZones)}
              />
              {zoneRefresh && <LoadZones searchFilter={searchZoneFilter} />}
            </FormField>
            {linkedServiceCategories.length > 0 && (
              <FormField
                boxProps={{ flex: 1, paddingBottom: ['large', 0] }}
                label="Categories"
              >
                <Box flexWrap="wrap" gridGap="small">
                  {linkedServiceCategories.sort((a, b) => (a > b ? 1 : -1)).map(x => (
                    <Tag
                      key={x}
                      boxProps={{
                      width: 'fit-content',
                      fontSize: 'xsmall',
                      backgroundColor: primaryCategory === x ? '#43ac6a!important' : null,
                      onClick: () => {
                        provider.primary_category = x
                        setState({ primaryCategory: x })
                        setEntityState({ provider })
                      },
                    }}
                    >
                      <Text color={primaryCategory === x ? 'white' : 'bodyFontLightColor'}>
                        {Object.values(serviceCategories).find(category => category.workbaseReference === x)?.description}&nbsp;
                        {primaryCategory === x && <FontAwesomeIcon icon={faStar} />}
                      </Text>
                    </Tag>
                  ))}
                </Box>
                <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
                  <FontAwesomeIcon icon={faStar} /> - Primary Category
                </Text>
              </FormField>
            )}
          </Box>
          <Box flexDirection={['column', 'row']} marginBottom={[0, 'large']}>
            <FormField
              boxProps={{ flex: 1, marginRight: [0, 'medium'] }}
            >
              <LinkingTags
                callbacks={{
                  toggleItem: item => toggleService(item, 'add'),
                  toggleSearch: search => setState({ searchServiceTypeFilter: search }),
                  removeItem: item => toggleService(item),
                }}
                errorMessage={customErrors['provider.services']}
                fullWidth
                label="Services"
                linkedItems={mapEntityToWorkbaseReferenceArray(services, servicesSelected)}
                searchFilter={searchServiceTypeFilter}
                searchResults={mapServiceTypeResults(serviceCategories, services, servicesSelected)}
              />
            </FormField>
            <FormField boxProps={{ flex: 1 }}>
              <LinkingTags
                callbacks={{
                  toggleItem: item => toggleBookableService(item, 'add'),
                  toggleSearch: search => setState({ searchBookableServiceFilter: search }),
                  removeItem: item => toggleBookableService(item),
                }}
                fullWidth
                label="Bookable Services"
                linkedItems={mapEntityToWorkbaseReferenceArray(services, bookableServicesSelected)}
                searchFilter={searchBookableServiceFilter}
                searchResults={mapServiceTypeResults(serviceCategories, services, bookableServicesSelected)}
              />
            </FormField>
          </Box>
          <Scheduler
            slots={slots}
            callbacks={providerCallbacks('Scheduler', setState)}
            minimum_slots={Number.isNaN(parseInt(minimum_slots, 10)) ? 0 : parseInt(minimum_slots, 10)}
          />
        </Box>
      </DashboardModule>
      {entityState.id && (
        <DashboardModule
          customHeader={(
            <Box borderBottom="1px solid" borderColor="lineColor" padding="large" width="auto" alignItems="center">
              <Text color="bodyFontLightColor" letterSpacing="0.5px" fontSize="small" width="auto">
                Upcoming Blockouts
              </Text>
              <Box alignItems="center" justifyContent="flex-end" marginLeft="auto" width="auto">
                <Button
                  buttonStyle="primaryCreate"
                  onClick={() => createOrEditBlockout()}
                  size="medium"
                >
                  Add Blockout
                </Button>
              </Box>
            </Box>
          )}
        >
          <Blockouts
            blockouts={Object.values(blockouts).filter(x => x.user === `User/${entityState.id}`)}
            loading={blockoutsLoading}
          />
          {refresh && (
            <RefreshBlockouts setState={setState} userId={entityState.id} />
          )}
        </DashboardModule>
      )}
    </>
  )
}

ProviderDetail.propTypes = {
  userForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
  linkedZones: PropTypes.array,
}

export default ProviderDetail
