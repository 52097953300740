import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Box, Columns, ModalContext, PageHeader,
} from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import useCurrentDate from '@hooks/useCurrentDate'
import useReduxAction from '@hooks/useReduxAction'

import CapacityModal from '@modals/CapacityModal'
import weeks from '@functions/weeks'
import Tools from './components/Tools'
import MainPanel from './components/MainPanel'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    CapacityModal: {
      closeModal: () => setState({ showCapacityModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Refresh = (props) => {
  const { endOffset, startOffset } = props

  useReduxAction('capacities', 'loadCapacities', {
    filterStart: `${startOffset}d`,
    filterEnd: `${endOffset}d`,
  }, [endOffset, startOffset])

  return null
}

const defaultState = {
  calendarDates: [],
  endOffset: null,
  refresh: false,
  selectedCategories: [],
  selectedWeek: { id: 0, name: 'This Week' },
  selectedZones: [],
  showCapacityModal: false,
  startOffset: null,
}

const Calendar = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    calendarDates, endOffset, refresh, selectedWeek, selectedCategories, selectedZones, showCapacityModal, startOffset,
  } = state

  const { serviceCategories, zones } = useSelector(reduxState => reduxState.entities)

  const { defaultOffset, firstCalendarDay } = useCurrentDate()

  const getCalendarDates = useCallback(({ currentPage = 0 }) => {
    const days = 5
    const newCalendarDates = []

    let day = firstCalendarDay().plus({ days: currentPage * 7 })
    const start = defaultOffset + (currentPage * 7)
    const end = start + 6
    while (newCalendarDates.length < days){
      if (day.weekday < 6) newCalendarDates.push(day)
      day = day.plus({ days: 1 })
    }

    setState({
      calendarDates: newCalendarDates,
      endOffset: end,
      refresh: true,
      startOffset: start,
    })
  }, [])

  useEffect(() => {
    getCalendarDates({})
  }, [])

  const updateSelectedCategories = (checked, id) => {
    if (checked){
      setState({ selectedCategories: [...selectedCategories, Object.values(serviceCategories).find(x => x.id === id)] })
    } else {
      setState({ selectedCategories: selectedCategories.filter(x => x.id !== id) })
    }
  }

  const updateSelectedWeek = (currentPage) => {
    getCalendarDates({ currentPage })

    const selected = weeks.find(x => x.id === parseInt(currentPage, 10))
    setState({ selectedWeek: selected })
  }

  const updateSelectedZones = (checked, id) => {
    if (checked){
      setState({ selectedZones: [...selectedZones, Object.values(zones).find(x => x.id === id)] })
    } else {
      setState({ selectedZones: selectedZones.filter(x => x.id !== id) })
    }
  }

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    calendarDates,
    callbacks: {
      showCapacityModal: (payload) => {
        setModalData('CapacityModal', payload)
        setState({ showCapacityModal: true })
      },
      toggleUpdateSelectedCategories: (checked, val) => updateSelectedCategories(checked, val),
      toggleUpdateSelectedWeek: currentPage => updateSelectedWeek(currentPage),
      toggleUpdateSelectedZones: (checked, val) => updateSelectedZones(checked, val),
    },
    categories: selectedCategories.length > 0 ? selectedCategories : Object.values(serviceCategories),
    selectedCategories,
    selectedWeek,
    selectedZones,
    weeks,
    zones: selectedZones.length > 0 ? selectedZones : Object.values(zones),
  }

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageHeader
          boxProps={{ height: [112, 105], justifyContent: 'flex-start' }}
          title="Live Capacity Dashboard"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns
            boxProps={{ marginTop: 'large' }}
            flexDirection={['column', 'column', 'row']}
          >
            <Columns.Main>
              <MainPanel />
            </Columns.Main>

            <Columns.Sidebar>
              <Tools />
            </Columns.Sidebar>
          </Columns>
        </Box>
        {refresh && (
          <Refresh endOffset={endOffset} startOffset={startOffset} />
        )}
        <CapacityModal
          callbacks={callbacks('CapacityModal', setState)}
          showModal={showCapacityModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Calendar
