/* eslint-disable array-callback-return */
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Box, Checkbox, ListItem, LoadingBubbles, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useServiceJobChecklistItem from '@hooks/useServiceJobChecklistItem'
import { groupBy } from '@functions/groupBy'

const updateChecklistItem = (checklist, updateFn) => {
  updateFn(checklist).then(({ success, errors }) => {
    if (!success) toast.warning(errors[0])
  })
}

const defaultState = {
  canUpdateEditor: false,
  canUpdateQC: false,
  checkedIds: [],
  checklist: [],
}

const Checklist = (props) => {
  const { postProductionChecklist } = props

  const [state, setState] = useSetState(defaultState)
  const { canUpdateEditor, canUpdateQC, checkedIds, checklist } = state

  const { assignedUserRole, currentUser } = useContext(PageContext)

  const {
    callbacks: {
      updateServiceJobChecklistItem: updateFn,
    },
    updating,
  } = useServiceJobChecklistItem()

  useEffect(() => {
    const checkedProdIds = []
    const list = groupBy(postProductionChecklist, 'description')

    postProductionChecklist.map((x) => {
      if (x.checked) checkedProdIds.push(x.id)
    })

    setState({ checkedIds: checkedProdIds, checklist: list })
  }, [postProductionChecklist])

  useEffect(() => {
    if (['ProductionManager'].includes(currentUser.userRole?.name)
    || assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'QualityControl')){
      setState({ canUpdateEditor: false, canUpdateQC: true })
    }
    if (assignedUserRole?.find(x => x.serviceJobUserRole?.name === 'EditorDrafterCopywriter')){
      setState({ canUpdateEditor: true, canUpdateQC: false })
    }
  }, [assignedUserRole, currentUser])

  const updateChecklist = (id) => {
    let updatedChecklistIds = []

    if (checkedIds.includes(id)){
      updatedChecklistIds = checkedIds.filter(x => x !== id)
    } else {
      updatedChecklistIds = [...checkedIds, id]
    }

    setState({ checkedIds: updatedChecklistIds })

    const checklistDetails = {
      id,
      checked: updatedChecklistIds.includes(id),
    }

    updateChecklistItem(checklistDetails, updateFn)
  }

  return (
    <Box flexDirection="column">
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text fontSize="small">CHECKLIST</Text>
        {Object.keys(checklist).length > 0 && (
          <Box width="auto" height={20}>
            {updating ? <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />
            : <StatusBadge color="#43ac6a" ghost text="Updated" />}
          </Box>
        )}
      </Box>
      <Box flexDirection="column" paddingTop={Object.keys(checklist).length ? 'large' : 'medium'}>
        {Object.keys(checklist).length > 0 && (
          <ListItem
            boxProps={{ paddingBottom: 'large' }}
            disableHover
            flexDirection="row"
          >
            <Box width="auto" paddingLeft="6px" paddingRight="large"><Text fontSize="xsmall">E</Text></Box>
            <Box width="auto" paddingLeft="6px" paddingRight="large"><Text fontSize="xsmall">QC</Text></Box>
            <Box width="auto" paddingLeft="1px"><Text fontSize="xsmall">DESCRIPTION</Text></Box>
          </ListItem>
        )}
        {Object.keys(checklist).map(item => (
          <ListItem
            boxProps={{ paddingBottom: 'large' }}
            disableHover
            flexDirection="row"
            key={item.id}
          >
            {checklist[item].sort((a, b) => a.serviceJobUserRoleId - b.serviceJobUserRoleId).map((x) => {
              const canUpdate = (x.serviceJobUserRoleId === 1 && canUpdateEditor) || (x.serviceJobUserRoleId === 2 && canUpdateQC)
              return (
                <Checkbox
                  checked={checkedIds.includes(x.id)}
                  onClick={e => (canUpdate ? updateChecklist(x.id) : e.preventDefault())}
                  style={{ opacity: canUpdate ? '100%' : '60%' }}
                />
              )
            })}
            <Text fontSize="xsmall">{item}</Text>
          </ListItem>
        ))}
        {!Object.keys(checklist).length > 0 && (
          <Text fontSize="xsmall" color="bodyFontLightColor">
            No checklist available
          </Text>
        )}
      </Box>
    </Box>
  )
}

Checklist.propTypes = {
  postProductionChecklist: PropTypes.array,
}

export default Checklist
